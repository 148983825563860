import { Link } from "react-router-dom";
import * as S from "../StepStyles";
import KeywordsDropdown from "@components/library/Dropdowns/KeywordsDropdown/KeywordsDropdown";
import { isValidURL } from "@utils/textUtils";
import { COUNTRY_OPTIONS } from "@constants";

const companyRoleOptions = ["Business Development", "Researcher", "CEO", "CSO"].map((text) => ({
  key: text,
  text: text,
  value: text,
}));

function CompanyScientist({ profileInfo, setProfileInfo, onClickContactUs }) {
  const { country_code, role, disciplines, areasOfExpertise, techniques } = profileInfo;

  const handleCompany = (e) => {
    setProfileInfo({
      ...profileInfo,
      companyName: e.target.value,
    });
  };

  const handleCompanyWebsite = (e) => {
    setProfileInfo({
      ...profileInfo,
      companyURL: e.target.value,
    });
  };

  const handleCountry = (e, selection) => {
    if (selection?.action === "select-option") {
      setProfileInfo({
        ...profileInfo,
        country_code: e.value,
      });
    }
  };

  const handleRole = (e, selection) => {
    if (selection?.action === "select-option") {
      setProfileInfo({
        ...profileInfo,
        role: e.value,
      });
    }
  };

  return (
    <>
      <S.DropdownRow>
        <S.DropdownTitle>Company Name</S.DropdownTitle>
        <S.Input
          placeholder="Company name"
          onChange={handleCompany}
          value={profileInfo?.companyName || ""}
          data-testid="company-scientist-company-name-input"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>Company Website</S.DropdownTitle>
        <S.Input
          placeholder="Company Website"
          onChange={handleCompanyWebsite}
          value={profileInfo?.companyURL || ""}
          data-testid="company-scientist-company-website-input"
          error={profileInfo?.companyURL?.length && !isValidURL(profileInfo?.companyURL)}
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>Where is your company located?</S.DropdownTitle>
        <S.CompanyDropdownNew
          selection
          placeholder="Search countries"
          value={country_code}
          options={COUNTRY_OPTIONS}
          onChange={handleCountry}
          closeMenuOnSelect
          disableIconColor
          defaultShadow
          data-testid="company-scientist-country-dropdown"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>Which of the following best describes your role?</S.DropdownTitle>
        <S.CompanyDropdownNew
          placeholder="Select your role"
          closeMenuOnSelect
          disableIconColor
          defaultShadow
          selection
          options={companyRoleOptions}
          onChange={handleRole}
          value={role}
          data-testid="company-scientist-role-dropdown"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>
          Which academic departments or disciplines are most relevant to your work?
        </S.DropdownTitle>
        <S.DropdownSubtitle>You can add up to 3.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="disciplines"
          value={disciplines ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, disciplines: selection })}
          maxValues={3}
          placeholder="Add department or discipline"
          showSuggestions
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What are your areas of expertise?</S.DropdownTitle>
        <S.DropdownSubtitle>You can add up to 5.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="areas_of_expertise"
          value={areasOfExpertise ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, areasOfExpertise: selection })}
          maxValues={5}
          placeholder="Add areas of expertise"
          showSuggestions
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What are your investigational techniques?</S.DropdownTitle>
        <S.DropdownSubtitle>
          You can add up to 15.{" "}
          <Link
            onClick={onClickContactUs}
            to="/contact?message=Hello, I can't find all of my research techniques as an option. My techniques are:"
            target="_blank"
          >
            Contact us
          </Link>{" "}
          if you don&rsquo;t see your techniques.
        </S.DropdownSubtitle>
        <KeywordsDropdown
          type="techniques"
          value={techniques ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, techniques: selection })}
          maxValues={15}
          placeholder="Add techniques"
          showSuggestions
        />
      </S.DropdownRow>
    </>
  );
}

export default CompanyScientist;
