import PropTypes from "prop-types";
import * as S from "./RadioButton.styles";

const RadioButton = ({
  text,
  description,
  value,
  compact = false,
  selected, // which of the radio buttons is currently selected
  onChange,
  width,
  testid = "",
}) => {
  return (
    <S.RadioContainer
      onClick={() => {
        onChange(value);
      }}
      width={width}
      compact={compact}
      selected={value === selected}
      data-testid={testid}
    >
      <S.RadioOuterCircle selected={value === selected}>
        <S.RadioInnerCircle selected={value === selected} />
      </S.RadioOuterCircle>
      <S.TextContainer>
        <S.TitleText compact={compact}>{text}</S.TitleText>
        <S.DescriptionText compact={compact}>{description}</S.DescriptionText>
      </S.TextContainer>
    </S.RadioContainer>
  );
};

RadioButton.propTypes = {
  /* Main title that shows at the top of the radio button */
  text: PropTypes.string.isRequired,

  /* Subtext used to further elaborate on the title */
  description: PropTypes.string,

  /* Callback function for when the radio button is clicked, should update selected */
  onChange: PropTypes.func.isRequired,

  /* The currently selected radio button (when used in a group). Should equal 'value' when the button is clicked */
  selected: PropTypes.string.isRequired,

  /* Internal value that represents this radio button's active state */
  value: PropTypes.string.isRequired,

  /* False by default, makes the radio button smaller by reducing padding and margins */
  compact: PropTypes.bool,
};

export default RadioButton;
