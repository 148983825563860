import styled from "styled-components";
import { Modal } from "semantic-ui-react";
import { Button } from "@components/library";
import { COLORS, FONTS } from "@constants";

const ResendConfirmationModal = ({ confirmationModalOpen, setConfirmationModalOpen }) => {
  return (
    <ConfirmationModal
      open={confirmationModalOpen}
      onClose={() => setConfirmationModalOpen(false)}
      size="tiny"
      closeOnDimmerClick={true}
      className="scrolling"
    >
      <ModalContent>
        <h1>Your verification email has been resent.</h1>
        <p>Please check your spam and quarantine folders in your email.</p>
        <p>
          If you continue to have problems, please{" "}
          <a href="/contact" target="_blank">
            contact us
          </a>{" "}
          for assistance.
        </p>
        <Button type="button" onClick={() => setConfirmationModalOpen(false)}>
          Close
        </Button>
      </ModalContent>
    </ConfirmationModal>
  );
};

const ConfirmationModal = styled(Modal)`
  overflow: visible;
  color: ${COLORS.BLACK};
  ${FONTS.REGULAR_2};
`;
const ModalContent = styled(Modal.Content)`
  h1 {
    ${FONTS.HEADING_4_SEMIBOLD};
    color: ${COLORS.BLACK};
    border: none;
    padding-bottom: 12px;
  }
  p {
    ${FONTS.REGULAR_2};
    color: ${COLORS.BLACK};
    border: none;
    padding-bottom: 8px;
  }
`;

export default ResendConfirmationModal;
