import BlankModal, { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import SignUpBase from "./SignUpBase";
import { Company } from "@tsTypes/schema";

interface Props extends BlankModalProps {
  company: (Pick<Company, "identifier" | "logo" | "company_name"> & Record<string, any>) | null;
}

const SignUpModal = ({ isOpen, onClose, company }: Props) => {
  if (!isOpen || !company) return null;

  return (
    <BlankModal closeOnDimmerClick height="100%" hideCloseButton isOpen={isOpen} onClose={onClose}>
      <SignUpBase company={company} />
    </BlankModal>
  );
};

export default SignUpModal;
