import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getSSOEmailDomains } from "@requests/sso";

const useSSOSensitiveEmail = (
  initialValue = ""
): {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  isEmailSSOEnabled: boolean;
  redirectToSSO: () => void;
} => {
  const [email, setEmail] = useState(initialValue);
  const [domains, setDomains] = useState<string[]>([]);
  const [excludedEmails, setExcludedEmails] = useState<string[]>([]);
  const [isEmailSSOEnabled, setIsEmailSSOEnabled] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getSSOEmailDomains();
      setDomains(data.email_domains);
      setExcludedEmails(data.excluded_emails);
    })();
  }, []);

  useEffect(() => {
    const emailParts = email.split("@");
    setIsEmailSSOEnabled(
      emailParts.length > 1 &&
        domains.includes(emailParts[emailParts.length - 1]) &&
        !excludedEmails.includes(email.toLowerCase())
    );
  }, [email, domains]);

  const redirectToSSO = () => {
    window.location.href = `/sso/redirect?email=${email}`;
  };

  return {
    email,
    setEmail,
    isEmailSSOEnabled,
    redirectToSSO,
  };
};

export { useSSOSensitiveEmail };
