import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { PersistConfig, persistReducer } from "redux-persist";

import reducers from "./reducers";

const {
  defaultReducer,
  profileReducer,
  companyReducer,
  windowDimensionsReducer,
  messengerReducer,
} = reducers;

const defaultConfig = {
  key: "defaultReducer",
  storage: storage,
  blacklist: ["filters", "searchTerm", "researchFollows"],
};

const profilesConfig = {
  key: "profileReducer",
  storage: storage,
  whitelist: ["currentUser"],
};

const messengerConfig: PersistConfig<any> = {
  key: "messengerReducer",
  storage: storage,
};

const rootReducer = combineReducers({
  defaultReducer: persistReducer(defaultConfig, defaultReducer),
  profiles: persistReducer(profilesConfig, profileReducer),
  messenger: persistReducer(messengerConfig, messengerReducer),
  company: companyReducer,
  window: windowDimensionsReducer,
});

// Redux persist
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["defaultReducer", "profiles", "window", "company"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
