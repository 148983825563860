import { UserLinks } from "./ScientistNavbarLinks";
import NavbarLink from "@components/Navbar/NavbarLink";
import { t } from "@utils/i18n";

const UniversityAdminNavbarLinks = () => {
  return (
    <UserLinks>
      <NavbarLink to="/home">{t("components.navbar.links.marketplace")}</NavbarLink>
      <NavbarLink to="/university_dashboard/proposals" withNewTag>
        {t("components.navbar.links.university_proposals")}
      </NavbarLink>
      <NavbarLink to="/university_dashboard/team">
        {t("components.navbar.links.university_team")}
      </NavbarLink>
    </UserLinks>
  );
};

export default UniversityAdminNavbarLinks;
