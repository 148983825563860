import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS, FONTS } from "@constants";
import { Icon, IconButton, MoreActionsDropdown, UserPreview } from "@components/library";
import type { ExtendedDropdownOption } from "@components/library/Dropdowns/DropdownOption";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/store";
import { InboxMessageThread } from "@tsTypes/index";

interface Props {
  currentThread: InboxMessageThread;
  isModal?: boolean;
}

const Participants = ({ currentThread, isModal }: Props) => {
  const currentUserRole = useSelector((state: RootState) => state.profiles.currentUser.role);

  const scientist = currentThread.initiator;

  const moreActionsOptions: ExtendedDropdownOption[] = [
    {
      value: "VIEW_PROFILE",
      label: `View Profile`,
      onSelect: () => window.open(`/profile/${scientist?.profile_id}`, "_blank"),
      iconName: "Profile",
    },
  ];

  // Create map of participants grouped by location
  const dmParticipants = new Map();
  [...(currentThread.recipients ?? [])].forEach((participant) => {
    if (!participant) return;
    if (!dmParticipants.has(participant.location)) {
      dmParticipants.set(participant.location, [participant]);
      return;
    }
    dmParticipants.get(participant.location).push(participant);
  });

  const companyHidden = Boolean(currentThread.company?.private_alias);

  return currentThread.proposal ? (
    <>
      <div>
        <SectionTitle>Scientist</SectionTitle>
        <UserPreviewContainer>
          <UserPreview size="sm" user={scientist} />
          {isModal && (
            <MoreActionsDropdown options={moreActionsOptions} menuHorizontalPosition="right">
              <IconButton size="sm" variant="fourth" iconName="More Actions" tooltipWidth="100px" />
            </MoreActionsDropdown>
          )}
        </UserPreviewContainer>
      </div>
      <CompanySectionTitle>
        <UnstyledLink
          as={companyHidden ? "div" : Link}
          to={`/company/${currentThread.company.identifier}`}
          target="_blank"
        >
          {companyHidden
            ? currentThread.company?.private_alias ?? "Private Company"
            : currentThread.company.company_name}
        </UnstyledLink>
      </CompanySectionTitle>
      <CompanyMembers isModal={isModal}>
        {companyHidden ? (
          <UserPreviewContainer>
            <UserPreview size="sm" userRole={1} hidden disableLink />
          </UserPreviewContainer>
        ) : (
          currentThread.company_members.map((member) => (
            <UserPreviewContainer key={`conversation-participant-${member.name}`}>
              <UserPreview size="sm" user={member} disableLink userRole={1} />
            </UserPreviewContainer>
          ))
        )}
      </CompanyMembers>
      {currentUserRole === 1 && (
        <Warning isModal={isModal}>
          <Icon name="Proposal Privacy" color={COLORS.ORANGE} size="lg" margin="0 0 12px 0" />
          This conversation is stored with the proposal so you and your team can reference it in the
          future.
        </Warning>
      )}
    </>
  ) : (
    <DMParticipants>
      {Array.from(dmParticipants.keys()).map((location) => (
        <div key={location}>
          <DMSectionTitle>{location}</DMSectionTitle>
          {dmParticipants.get(location).map((participant) => (
            <UserPreviewContainer key={`user-preview-${participant.id}`}>
              <UserPreview size="sm" user={participant} userRole={participant.role} />
            </UserPreviewContainer>
          ))}
        </div>
      ))}
    </DMParticipants>
  );
};

export default Participants;

const UnstyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
`;

const SectionTitle = styled.div`
  ${FONTS.SEMIBOLD_2}
  color: ${COLORS.NEUTRAL_500};
  margin-bottom: 12px;
`;

const CompanySectionTitle = styled(SectionTitle)`
  margin-top: 32px;
`;

const DMParticipants = styled.div`
  margin-top: -32px;
`;

const DMSectionTitle = styled(SectionTitle)`
  margin-top: 32px;
`;

const CompanyMembers = styled.div`
  ${({ isModal }) => isModal && "min-height: 158px"};
  ${({ isModal }) => !isModal && "margin-bottom: 16px"};
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.NEUTRAL_300};
  }
`;

const Warning = styled.div`
  ${FONTS.MEDIUM_3};
  background-color: ${COLORS.ORANGE_100};
  color: ${COLORS.ORANGE};
  border-radius: 6px;
  padding: 20px;
  height: 142px;
  ${({ isModal }) =>
    isModal &&
    `
      height: 88px;
      display: flex;
      align-items: center;
      ${FONTS.MEDIUM_2};
      gap: 10px;
    `}
`;

const UserPreviewContainer = styled.div`
  margin-bottom: 10px;
  margin-left: 16px;
  display: flex;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;
