export const NAVBAR_HIDDEN_PATHS = [
  "/partner", // white label
  "/w/", // white label
  "/submit/",
  "/preview-rfp/",
  "/get-started",
  "/login",
  "/onboarding",
  "/review_system",
];

export const NAVBAR_BORDER_PATHS = [
  "/inbox",
  "/account",
  "/reviewer_dashboard",
  "/reviewer_dashboard/teams",
  "/reviewer_dashboard/proposals",
  "/proposal_library",
  "/network",
];
