import { useState } from "react";

import Step3CompanyScientist from "./CompanyScientist";
import Step3CompanySponsor from "./CompanySponsor";
import Step3ResearchInstitutionAdmin from "./ResearchInstitutionAdmin";
import Step3ResearchInstitutionScientist from "./ResearchInstitutionScientist";

import * as S from "../StepStyles";
import { Button, TextInput } from "@components/library";
import { isValidURL } from "@utils/textUtils";
import { t } from "@utils/i18n";
import { COLORS, FEATURE_FLAGS } from "@constants";

export const COMPANY_SPONSOR_TITLE_LIMIT = 50;

const Step3 = ({
  userType,
  handleOnboard,
  onBack,
  profileInfo,
  setProfileInfo,
  user,
  isSubmitting,
}) => {
  const {
    company,
    role,
    title,
    companyName,
    companyURL,
    country_code,
    disciplines,
    areasOfExpertise,
    techniques,
    university,
    universityExpertise,
    universityHasCountry,
    howDidYouHearAboutUs,
  } = profileInfo;

  const [isContactUsClicked, setIsContactUsClicked] = useState(false);

  let disabled = isSubmitting;
  if (userType === "company-sponsor")
    disabled ||= !(company && role && title && title.length <= COMPANY_SPONSOR_TITLE_LIMIT);
  if (userType === "company-scientist")
    disabled ||= !(
      companyName &&
      isValidURL(companyURL) &&
      country_code &&
      role &&
      disciplines?.length &&
      areasOfExpertise?.length &&
      (techniques?.length || isContactUsClicked)
    );
  if (userType === "research-administrator")
    disabled ||= !(
      university &&
      role &&
      universityExpertise &&
      (universityHasCountry || country_code)
    );
  if (userType === "research-scientist")
    disabled ||= !(
      university &&
      role &&
      disciplines?.length &&
      areasOfExpertise?.length &&
      (techniques?.length || isContactUsClicked) &&
      (universityHasCountry || country_code)
    );

  const onContinue = () => {
    if (userType === "company-sponsor") return handleOnboard("sponsor");
    if (userType === "research-administrator") return handleOnboard("admin");
    return handleOnboard("scientist");
  };

  return (
    <S.StepContainer>
      <S.StepTitle>Work Background</S.StepTitle>
      {userType === "company-sponsor" && (
        <Step3CompanySponsor
          profileInfo={profileInfo}
          setProfileInfo={setProfileInfo}
          email={user.email}
        />
      )}
      {userType === "company-scientist" && (
        <Step3CompanyScientist
          profileInfo={profileInfo}
          setProfileInfo={setProfileInfo}
          onClickContactUs={() => {
            setIsContactUsClicked(true);
          }}
        />
      )}
      {userType === "research-administrator" && (
        <Step3ResearchInstitutionAdmin profileInfo={profileInfo} setProfileInfo={setProfileInfo} />
      )}
      {userType === "research-scientist" && (
        <Step3ResearchInstitutionScientist
          profileInfo={profileInfo}
          setProfileInfo={setProfileInfo}
        />
      )}
      {FEATURE_FLAGS.HOW_DID_YOU_HEAR_ABOUT_US && (
        <S.DropdownRow>
          <S.DropdownTitle>
            {t("pages.onboarding_container.how_did_you_hear_about_us.label")}
          </S.DropdownTitle>
          <S.DropdownSubtitle>Optional</S.DropdownSubtitle>
          <TextInput
            placeholder={t("pages.onboarding_container.how_did_you_hear_about_us.placeholder")}
            placeholderColor={COLORS.NEUTRAL_500}
            value={howDidYouHearAboutUs}
            onChange={(e) => {
              setProfileInfo({ ...profileInfo, howDidYouHearAboutUs: e.target.value });
            }}
            hideClearButton
          />
        </S.DropdownRow>
      )}
      <S.ButtonContainer>
        <Button type="button" variant="ghost" width="120px" onClick={() => onBack("step2")}>
          Back
        </Button>
        <Button
          type="button"
          disabled={disabled}
          onClick={onContinue}
          data-testid="company-sponsor-step-4-submit-button"
          width="120px"
        >
          Submit
        </Button>
      </S.ButtonContainer>
    </S.StepContainer>
  );
};

export default Step3;
