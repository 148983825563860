import { useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import styled from "styled-components";

import toast from "@components/Toast";
import { Button, Icon } from "@components/library";
import { COLORS, FEATURE_FLAGS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { resendEmailInvite, signOut, verifyUser } from "src/requests/users";
import ResendConfirmationModal from "./ResendConfirmationModal";

import gmail from "@images/icons/gmail.svg";
import outlook from "@images/icons/outlook.svg";

function UserVerificationCode({ onContinue }) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);

  const handleResendEmailInvite = () => {
    resendEmailInvite().then((res) => {
      if (res?.data.success) {
        toast.success("Resent verification email");
      } else {
        setConfirmationModalOpen(true);
      }
    });
  };

  const handleOnChange = (verificationCode: string) => {
    if (verificationCode.length && error) setError(false);
    setCode(verificationCode);
  };

  const handleVerification = async () => {
    if (await verifyUser(code)) {
      onContinue();
    } else {
      setError(true);
    }
  };

  const isNextButtonDisabled = code.length !== 4 || error;

  return (
    <>
      <ResendConfirmationModal
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
      />
      <StepContainer step={1} data-testid="onboarding-step1">
        <IconContainer>
          <Icon size="xxl" name="Message" color={COLORS.HALO_BLUE} margin="0 0 16px" />
          <StepTitle>Verify your email address</StepTitle>
        </IconContainer>
        <StyledCodeInput
          error={error}
          required
          type="number"
          fields={4}
          fieldHeight={85}
          fieldWidth={75}
          placeholder={[" ", " ", " ", " "]}
          onChange={handleOnChange}
        />
        <DropdownRow style={{ width: "324px" }}>
          {error && (
            <DropdownTitle style={{ color: COLORS.RED }}>
              This doesn’t look like the most recent code that was sent.
            </DropdownTitle>
          )}
          <DropdownTitle>
            Please enter the code we sent to your email. Didn’t receive an email?{" "}
            <ResendEmailLink onClick={handleResendEmailInvite}>Resend email.</ResendEmailLink>
          </DropdownTitle>
          <EmailSection>
            <OpenEmail href="https://gmail.com/" target="_blank">
              <Button type="button" variant="ghost" size="sm">
                <img src={gmail} width="20" />
                Open Gmail
              </Button>
            </OpenEmail>
            <OpenEmail href="https://outlook.com/" target="_blank">
              <Button type="button" variant="ghost" size="sm">
                <img src={outlook} />
                Open Outlook
              </Button>
            </OpenEmail>
          </EmailSection>
          <Padding height={20} />
          <>
            {FEATURE_FLAGS.SIGN_OUT_ON_ONBOARDING && (
              <Button
                type="button"
                variant="destructive-secondary"
                onClick={async () => {
                  await signOut();
                }}
                margin="0 16px 0 0"
              >
                Sign Out
              </Button>
            )}
            <Button
              type="button"
              data-testid="sign-up-step1-continue-button"
              disabled={isNextButtonDisabled}
              onClick={handleVerification}
            >
              Continue
            </Button>
          </>
        </DropdownRow>
      </StepContainer>
    </>
  );
}

export default UserVerificationCode;

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const StepContainer = styled.div`
  padding: 0;
  width: 500px;
  ${FONTS.REGULAR_2};

  ${({ step, center }) =>
    step === 1 || center
      ? `
        display: flex;
        flex-direction: column;
        align-items: center;
      `
      : ""}

  ${TABLET_MEDIA_QUERY} {
    padding: 0 30px;
    width: 100%;
  }
`;

const StepTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ center }) => center && `justify-content: center;`}
  color: ${COLORS.BLACK};
  ${({ large }) => (large ? FONTS.HEADING_3_SEMIBOLD : FONTS.HEADING_4_SEMIBOLD)};
  margin-bottom: 30px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 324px;
`;

const DropdownRow = styled.div`
  position: relative;
  margin-bottom: 28px;

  &:last-of-type {
    margin-bottom: 35px;
  }
`;

const DropdownTitle = styled.div`
  ${FONTS.MEDIUM_2};
  width: 100%;
  padding-bottom: 7px;
  color: ${COLORS.BLACK};
`;

// Styles below overwrite library's default styles to match our design system
const StyledCodeInput = styled(ReactCodeInput)`
  margin-bottom: 33px;
  width: unset !important;
  > div {
    > input {
      background: ${COLORS.NEUTRAL_100};
      border: 0.5px solid ${COLORS.HALO_BLUE} !important;
      border-radius: 4px;
      color: ${COLORS.BLACK};
      font-size: 32px;
      caret-color: ${COLORS.BLACK} !important;
    }
    > input:focus {
      border: 2px solid ${COLORS.HALO_BLUE} !important;
    }
    > input:not(:last-child) {
      margin-right: 8px;
    }
    > input:focus + input {
      border-left: 0.5px solid ${COLORS.HALO_BLUE};
    }
    > input:focus + input:not(:placeholder-shown) {
      border-left: 0.5px solid ${COLORS.HALO_BLUE};
    }
    > input:last-child:focus,
    input:not(:placeholder-shown) {
      border-color: ${COLORS.HALO_BLUE};
    }
    > input {
      border-color: ${(props) => props.error && COLORS.RED} !important;
    }
  }
`;

const ResendEmailLink = styled.span`
  ${FONTS.REGULAR_2};
  text-align: left;
  white-space: no-wrap;
  color: ${COLORS.HALO_BLUE};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const Padding = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : "10px")};
  display: block;
`;

const EmailSection = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;
`;

const OpenEmail = styled.a`
  img {
    margin-right: 6px;
  }
`;
