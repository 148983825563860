import { WINDOW_DIMENSIONS } from "@constants";

const { DESKTOP, MOBILE, TABLET, TABLET_LANDSCAPE } = WINDOW_DIMENSIONS;

export const UPDATE_WINDOW_DIMENSION = "UPDATE_WINDOW_DIMENSION";

export const updateWindowDimension = () => (dispatch) =>
  dispatch({
    type: UPDATE_WINDOW_DIMENSION,
    payload: {
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth <= MOBILE,
      isTablet: window.innerWidth <= TABLET,
      isDesktop: window.innerWidth >= DESKTOP,
      isTabletLandscape: window.innerWidth <= TABLET_LANDSCAPE,
    },
  });
