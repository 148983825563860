import { StyleSheet } from "aphrodite";
import { COLORS, WINDOW_DIMENSIONS, JS_FONTS } from "@constants";

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  LoginModal_modal: {
    borderRadius: "3px",
    padding: "30px 30px 14px",
  },
  LoginModal_innerContainer: {
    backgroundColor: "white",
  },
  LoginModal_logoContainer: {
    marginTop: "8px",
    textAlign: "center",
  },
  LoginModal_closeicon: {
    position: "absolute",
    top: "24px",
    right: "24px",
  },
  LoginModal_hero: {
    marginTop: "15px",
  },
  LoginModal_headline: {
    color: COLORS.BLACK,
    ...JS_FONTS.HEADING_4_SEMIBOLD,
    marginBottom: "5px",
    textAlign: "center",
  },
  LoginModal_subheadline: {
    color: COLORS.BLACK,
    ...JS_FONTS.REGULAR_2,
    textAlign: "center",
  },
  LoginModal_subheadline_rfp: {
    color: COLORS.BLACK,
    textAlign: "center",
    ":nth-child(2)": {
      ...JS_FONTS.REGULAR_1,
      marginBottom: "12px",
    },
    ":last-child": {
      color: COLORS.NEUTRAL_500,
      ...JS_FONTS.REGULAR_2,
      marginBottom: "8px",
    },
  },
  LoginModal_innerLeftPane: {
    width: "50%",
    display: "inline-block",
    verticalAlign: "top",
    padding: "20px 5px 0",
    ...JS_FONTS.MEDIUM_2,
    [MOBILE_MEDIA_QUERY]: {
      width: "100%",
      margin: "auto",
      padding: "15px 0 0",
    },
  },
  LoginModal_modalText: {
    color: COLORS.BLACK,
    ...JS_FONTS.REGULAR_2,
    width: "90%",
    marginBottom: "15px",
    display: "flex",
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: "10px",
      ":last-child": {
        marginBottom: "5px",
      },
    },
  },
  LoginModal_lineItem: {
    marginLeft: "5px",
  },
  LoginModal_innerRightPane: {
    width: "50%",
    display: "inline-block",
    padding: "5px 0 0",
    [MOBILE_MEDIA_QUERY]: {
      width: "100%",
      margin: "auto",
    },
  },
  LoginModal_innerRightPane_rfp: {
    padding: "5px 0 0",
    margin: "0 auto",
  },
  LoginModal_formField: {
    marginTop: "15px",
    marginBottom: "15px",
    ":first-child": {
      margin: "0",
    },
  },
  LoginModal_inputField: {
    width: "100%",
    border: `1px solid ${COLORS.NEUTRAL_200}`,
    padding: "4px 12px",
    borderRadius: "4px",
    height: "48px",
    ...JS_FONTS.REGULAR_3,
  },
  LoginModal_nameField: {
    margin: "0",
    width: "48%",
    display: "inline-block",
    ":first-child": {
      margin: "0 4% 0 0",
    },
  },
  LoginModal_placeholderText: {
    marginBottom: "10px",
    ...JS_FONTS.REGULAR_3,
    visibility: "hidden",
    textAlign: "center",
  },
  LoginModal_errorMessage: {
    marginBottom: "10px",
    color: "orange",
    ...JS_FONTS.REGULAR_3,
    textAlign: "center",
  },
  LoginModal_errorField: {
    border: "solid 1px orange",
  },
  LoginModal_alreadyHaveAccount: {
    textAlign: "center",
    display: "inline-block",
    ...JS_FONTS.MEDIUM_2,
    width: "100%",
    color: COLORS.BLACK,
  },
  LoginModal_signInLink: {
    marginLeft: "7px",
    ...JS_FONTS.REGULAR_2,
    display: "inline-block",
    color: COLORS.BLACK,
    cursor: "pointer",
    textDecoration: "underline",
  },
  LoginModal_forgotPasswordContainer: {
    textAlign: "center",
    marginTop: "14px",
  },
  LoginModal_footerText: {
    color: COLORS.BLACK,
    ...JS_FONTS.REGULAR_3,
  },
  LoginModal_forgotPassword: {
    textDecoration: "underline",
    color: COLORS.BLACK,
    ...JS_FONTS.REGULAR_3,
    marginBottom: "3px",
  },
  LoginModal_horizontalRule: {
    marginTop: "14px",
    marginBottom: "12px",
  },
});

export default styles;
