import UserVerificationCode from "@components/UserVerificationCode";
import { FONTS } from "@constants";
import haloLogo from "@images/logos/halo_logo.svg";
import { setCurrentUser } from "@redux/actions/profileActions";
import { getCompany } from "@requests/companies";
import { trackRegistration } from "@requests/hubspot";
import { UserNotVerifiedError, onboard } from "@requests/users";
import { useMutation } from "@tanstack/react-query";
import { identify } from "@utils/appUtils";
import { toastErrorHandler } from "@utils/requests";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import WhiteLabelNavbar from "../WhiteLabel/Navbar";
import ProgressBar from "./ProgressBar";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import toast from "@components/Toast";
import { t } from "@utils/i18n";

import { RootState } from "@redux/store";
import { ShowCompany } from "@tsTypes/index";
import * as S from "./OnboardingContainer.styles.jsx";

function OnboardingContainer() {
  const isWhiteLabel = location.pathname.startsWith("/partner");

  const [profileInfo, setProfileInfo] = useState<any>({});
  const [completionState, setCompletionState] = useState({
    step1: false,
    step2: false,
    step3: false,
  });
  const [userInfo, setUserInfo] = useState({
    work: "",
    role: "",
  });
  const [company, setCompany] = useState<ShowCompany | null>(null);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const { company_identifier } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (currentUser.profile_info?.id) {
      history.push("/");
    } else if (currentUser.verified) {
      onContinue("step1");
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (company_identifier) {
        try {
          const response = await getCompany(company_identifier);
          setCompany(response.company);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [company_identifier]);

  const onboardMutation = useMutation({
    mutationFn: (userType: string) => {
      return onboard(userType, profileInfo);
    },
    onError: (error, variables, context): void => {
      if (error instanceof UserNotVerifiedError) {
        setCompletionState({
          step1: false,
          step2: false,
          step3: false,
        });
        toast.error(t("pages.onboarding_container.user_not_verified_message"));
        return;
      }
      toastErrorHandler(error, variables, context);
    },
    onSuccess: async (updatedUser: any) => {
      dispatch(setCurrentUser(updatedUser));
      await trackRegistration({
        ...updatedUser,
        ...(updatedUser.userType === "admin" && { role: 2 }),
      });
      identify(updatedUser);
      onContinue("step3");
    },
  });

  const handleOnboard = (userType: string) => {
    if (onboardMutation.isLoading) {
      return;
    }
    onboardMutation.mutate(userType);
  };

  const onVerify = () => {
    dispatch(
      setCurrentUser({
        ...currentUser,
        verified: true,
      })
    );
    onContinue("step1");
  };

  const updateValue = (value, newValue) => {
    if (newValue !== userInfo[value]) {
      setProfileInfo({
        ...(profileInfo?.university ? { university: profileInfo.university } : null),
      });
    }
    // if user selects a new work value, then clear role value
    if (value === "work" && newValue !== userInfo.work) {
      setUserInfo({
        work: newValue,
        role: "",
      });
    } else {
      setUserInfo({
        ...userInfo,
        [value]: newValue,
      });
    }
  };

  // when the back button is pressed, set the previous step to incomplete
  const onBack = (previousStep) => {
    setCompletionState({ ...completionState, [previousStep]: false });
  };

  // when the continue button is pressed, set the current step to complete
  const onContinue = (currentStep) => {
    setCompletionState({ ...completionState, [currentStep]: true });
  };

  // calculates current step based on completionState
  const currentStep = () => {
    const { step1, step2, step3 } = completionState;
    if (!step1) {
      return <UserVerificationCode onContinue={onVerify} />;
    } else if (!step2) {
      return (
        <Step2
          values={userInfo}
          setWork={(v) => updateValue("work", v)}
          setRole={(v) => updateValue("role", v)}
          onContinue={() => onContinue("step2")}
        />
      );
    } else if (!step3) {
      return (
        <Step3
          userType={userInfo.role}
          handleOnboard={handleOnboard}
          isSubmitting={onboardMutation.isLoading}
          onBack={onBack}
          profileInfo={profileInfo}
          setProfileInfo={setProfileInfo}
          user={currentUser}
        />
      );
    }
    return <Step4 userType={userInfo.role} />;
  };

  return (
    <S.OnboardingPage>
      {isWhiteLabel ? (
        <>
          <WhiteLabelNavbar company={company} branding={null} />
          <WhiteLabelBanner>Create an account</WhiteLabelBanner>
        </>
      ) : (
        <S.LogoBanner>
          <img src={haloLogo} />
        </S.LogoBanner>
      )}
      <ProgressBar completionState={completionState} />
      <StepContainer>{currentStep()}</StepContainer>
    </S.OnboardingPage>
  );
}

export default OnboardingContainer;

const WhiteLabelBanner = styled.div`
  ${FONTS.HEADING_1_SEMIBOLD};
  margin-top: 40px;
  margin-bottom: 30px;
`;

const StepContainer = styled.div`
  padding-bottom: 40px;
`;
