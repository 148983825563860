import { Link } from "react-router-dom";
import styled from "styled-components";

import { GradientButton, Icon } from "@components/library";
import { COLORS, FONTS, STYLES } from "@constants";

import haloLogo from "@images/halo-inverse.svg";

const Footer = () => {
  return (
    <OuterContainer>
      <InnerContainer>
        <TopContainer>
          <Logo src={haloLogo} alt="Halo home" />
          <EmailDigestCTAContainer>
            <Header>
              Discover tomorrow&apos;s
              <br />
              breakthroughs today.
            </Header>
            <SubHeader>
              Get new partnering opportunities
              <br />
              delivered to your inbox.
            </SubHeader>
            <div>
              <GradientButton
                size="md"
                width="127px"
                maxHeight="36px"
                // Trigger HubSpot CTA for Email Digest Signup
                // https://app.hubspot.com/calls-to-action/6895929/edit/125612443295/content
                className="hs-cta-trigger-button hs-cta-trigger-button-125612443295"
              >
                Subscribe
              </GradientButton>
            </div>
          </EmailDigestCTAContainer>
          <MobileSocialIcons>
            <a href="https://twitter.com/halodotscience" target="_blank" rel="noreferrer">
              <Icon name="Twitter" size="xl" color={COLORS.NEUTRAL_900} />
            </a>
            <a
              href="https://www.linkedin.com/company/halodotscience"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="LinkedIn" size="xl" color={COLORS.NEUTRAL_900} />
            </a>
          </MobileSocialIcons>
          <Line mobileOnly />
          <LinksContainer>
            <LinkColumn>
              <LinkHeader>Company</LinkHeader>
              <StyledLink as="a" href="/about">
                About
              </StyledLink>
              <StyledLink as="a" href="https://blog.halo.science">
                Blog
              </StyledLink>
              <StyledLink as="a" href="/careers">
                Careers
              </StyledLink>
              <StyledLink
                as="a"
                href="https://blog.halo.science/a-look-back-at-the-2019-halo-awards"
              >
                Halo Awards
              </StyledLink>
            </LinkColumn>
            <LinkColumn>
              <LinkHeader>Support</LinkHeader>
              <StyledLink as="a" href="https://knowledge.halo.science/faq">
                FAQs
              </StyledLink>
              <StyledLink as="a" href="https://knowledge.halo.science/">
                Help center
              </StyledLink>
              <StyledLink as="a" href="/contact">
                Contact us
              </StyledLink>
            </LinkColumn>
            <LinkColumn>
              <LinkHeader>Sponsors</LinkHeader>
              <StyledLink as="a" href="https://meetings.hubspot.com/sheena-pulapaka">
                Request a demo
              </StyledLink>
              <StyledLink as="a" href="https://info.halo.science/customer-stories">
                Customer stories
              </StyledLink>
              {/* TODO: (thaske) temporarily hide until we have this */}
              {/* <StyledLink to="/claim">Claim your page</StyledLink> */}
            </LinkColumn>
          </LinksContainer>
        </TopContainer>
        <SocialIcons>
          <a href="https://twitter.com/halodotscience" target="_blank" rel="noreferrer">
            <Icon name="Twitter" size="xl" color={COLORS.NEUTRAL_900} />
          </a>
          <a
            href="https://www.linkedin.com/company/halodotscience"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="LinkedIn" size="xl" color={COLORS.NEUTRAL_900} />
          </a>
        </SocialIcons>
      </InnerContainer>
      <Line />
      <InnerContainer>
        <BottomContainer>
          <BottomLinks>
            <StyledLink as="a" href="/privacy" color={COLORS.NEUTRAL_250}>
              Privacy Policy
            </StyledLink>
            <StyledLink as="a" href="/terms" color={COLORS.NEUTRAL_250}>
              Terms of Service
            </StyledLink>
            {/* TODO: (thaske) temporarily hide until we have this */}
            {/* <BottomLink>Cookie Policy</BottomLink> */}
            {/* <BottomLink>Accessibility Policy</BottomLink> */}
          </BottomLinks>
          <CopyRight>© 2023 Halo Cures, Inc.</CopyRight>
        </BottomContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

export default Footer;

const FOOTER_MEDIA_QUERY = `@media (max-width: 1090px)`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 76px ${STYLES.SIDE_PADDING}px 40px;

  ${FONTS.REGULAR_1}
  color: ${COLORS.WHITE};
  background-color: ${COLORS.NEUTRAL_900};

  ${FOOTER_MEDIA_QUERY} {
    padding: 80px 32px 32px;
  }
`;

const InnerContainer = styled.div`
  max-width: ${STYLES.MAX_WINDOW_WIDTH}px;
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 0 0 48px;

  ${FOOTER_MEDIA_QUERY} {
    flex-direction: column;
    gap: 0;
  }
`;

const Logo = styled.img`
  width: 65px;
  height: 32px;

  ${FOOTER_MEDIA_QUERY} {
    margin: 0 0 64px;
  }
`;

const Header = styled.div`
  ${FONTS.HEADING_3_REGULAR};
  margin: 0 0 39px;

  ${FOOTER_MEDIA_QUERY} {
    margin: 0 0 24px;
  }
`;

const SubHeader = styled.div`
  margin-bottom: 20px;
`;

const EmailDigestCTAContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 440px;

  @media (max-width: 1440px) {
    width: unset;
  }
`;

const StyledLink = styled(Link)<{ color?: string }>`
  width: fit-content;

  ${FONTS.REGULAR_1}

  color: ${({ color }) => color || COLORS.WHITE};

  &:hover {
    color: ${COLORS.BLUE_LIGHT_600};

    text-decoration: underline !important;
    text-decoration-color: ${COLORS.BLUE_600} !important;
    -webkit-text-decoration-color: ${COLORS.BLUE_600} !important;
    text-underline-offset: 4px !important;
  }

  &:focus {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 110px;

  ${FOOTER_MEDIA_QUERY} {
    flex-wrap: wrap;
    row-gap: 48px;

    margin: 42px 0 0;
  }
`;

const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & ${StyledLink} {
    line-height: 1 !important;
  }
`;

const LinkHeader = styled.div`
  ${FONTS.SEMIBOLD_1}

  margin: 0 0 24px;

  ${FOOTER_MEDIA_QUERY} {
    margin: 0 0 8px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  margin: 0 0 30px;

  ${FOOTER_MEDIA_QUERY} {
    display: none;
  }
`;

const MobileSocialIcons = styled.div`
  display: none;
  margin: 0 0 30px;

  ${FOOTER_MEDIA_QUERY} {
    display: flex;
    margin: 32px 0;
  }
`;

const Line = styled.div<{ mobileOnly?: boolean }>`
  height: 1px;
  width: 100vw;
  background: rgba(217, 217, 217, 0.2);

  ${({ mobileOnly }) =>
    mobileOnly &&
    `
      display: none;

      ${FOOTER_MEDIA_QUERY} {
        display: block;

        position: relative;
        left: -32px;
      }
    `}
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px 0 0;

  ${FOOTER_MEDIA_QUERY} {
    flex-direction: column;
    padding: 0;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  gap: 34px;

  line-height: 1;

  ${FOOTER_MEDIA_QUERY} {
    flex-direction: column;
    gap: 16px;

    margin: 48px 0 84px;
  }
`;

const CopyRight = styled.div`
  ${FONTS.REGULAR_1}

  color: ${COLORS.NEUTRAL_250};

  ${FOOTER_MEDIA_QUERY} {
    width: 100%;
    text-align: center;
  }
`;
