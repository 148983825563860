import request from "./request";
import { getCookie } from "@utils/requests";

const PORTAL_ID = "6895929";

// https://legacydocs.hubspot.com/docs/methods/forms/submit_form
export const submitForm = async (formId, fields) =>
  request.post(`https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${formId}`, {
    fields,
    context: {
      hutk: getCookie("hubspotutk"),
    },
  });

// track registration
export const trackRegistration = async (userInfo) => {
  const { email, first_name, last_name, role } = userInfo;

  // Scientist Registration
  let formId = "5fb3e11f-bf56-4b75-9a0b-632e5888e33f";

  if (role === 1) {
    // Sponsor Registration
    formId = "de8f6135-4e40-4d48-9b48-b5dcd36fd945";
  } else if (role === 2) {
    // University Admin Registration
    formId = "9091aea2-ed31-496e-97ee-eed2ff1f6b95";
  }

  if (
    window.location.host.includes("halocures.com") ||
    window.location.host.includes("halo.science")
  ) {
    try {
      const fields = [
        { name: "email", value: email },
        { name: "firstname", value: first_name },
        { name: "lastname", value: last_name },
      ];

      await submitForm(formId, fields);
    } catch (err) {
      console.error(err);
    }
  }
};

export const sponsorOnboarded = async (userInfo) => {
  const { email } = userInfo;

  // Sponsor Registration
  const formId = "de8f6135-4e40-4d48-9b48-b5dcd36fd945";

  if (
    window.location.host.includes("halocures.com") ||
    window.location.host.includes("halo.science")
  ) {
    try {
      const fields = [{ name: "email", value: email }];

      await submitForm(formId, fields);
    } catch (err) {
      console.error(err);
    }
  }
};
