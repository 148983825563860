import { setPrevUrlCookie } from "@utils/authUtils";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

const PrivateRoute = ({ component, options, ...props }) => {
  const { currentUser } = props;
  if (!currentUser.id) {
    setPrevUrlCookie(window.location.href);
    window.location.href = "/login";
  }

  return currentUser.id ? <Route {...options} {...props} component={component} /> : <></>;
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.profiles.currentUser,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
