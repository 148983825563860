import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import styled from "styled-components";
import { COLORS } from "@constants";
import { Button, Icon } from "@components/library";
import SlideModalBase, { SlideModalBaseProps } from "@components/library/ModalBases/SlideModalBase";

const MobileMenu = ({ isOpen, onClose }: SlideModalBaseProps) => {
  if (!isOpen) return null;

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const links: { name: string; isCurrentPage: boolean; to?: string; as?: string; href?: string }[] =
    [
      {
        name: "Marketplace",
        to: "/home",
        isCurrentPage: window.location.pathname.startsWith("/home"),
      },
      {
        name: "Blog",
        as: "a",
        href: "/blog",
        isCurrentPage: window.location.pathname.startsWith("/blog"),
      },
      {
        name: "For Scientists",
        to: "/scientists",
        isCurrentPage: window.location.pathname.startsWith("/scientists"),
      },
    ];

  return (
    <SlideModalBase isOpen={isOpen} onClose={onClose} direction="bottom" hideCloseButton>
      <Container>
        <NavLinks>
          <CloseButton type="button" onClick={onClose}>
            <Icon name="Close" size="lg" />
          </CloseButton>
          {links.map((link) => (
            <NavLink
              key={link.name}
              as={link.as}
              to={link.to}
              href={link.href}
              isCurrentPage={link.isCurrentPage}
            >
              {link.name}
            </NavLink>
          ))}
        </NavLinks>
        {!currentUser.id && (
          <a href="/login">
            <Button type="button" variant="secondary" margin="36px 0 0" width="100%">
              Sign in
            </Button>
          </a>
        )}
      </Container>
    </SlideModalBase>
  );
};

export default MobileMenu;

const Container = styled.div`
  position: relative;
  padding: 40px;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 40px;
  right: 32px;
  border: none;
  background-color: transparent;
`;
const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const NavLink = styled(Link)`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.7;
  color: ${({ isCurrentPage }) => (isCurrentPage ? COLORS.HALO_BLUE : COLORS.BLACK)};
  &:hover {
    color: ${COLORS.HALO_BLUE};
    text-decoration: none;
  }
`;
