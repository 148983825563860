import * as S from "./ProgressBar.styles";

const Step1Progress = ({ completed }) => {
  return (
    <S.ProgressStep z={4}>
      <S.Checkmark name="checkmark" completed={completed ? 1 : 0} />
      <S.ProgressStepText completed={completed ? 1 : 0}>
        <S.ProgressStepTitle>{completed ? "Completed" : "Step 1"}</S.ProgressStepTitle>
        <S.ProgressStepDescription>Verify your email address</S.ProgressStepDescription>
      </S.ProgressStepText>
    </S.ProgressStep>
  );
};

const Step2Progress = ({ completed }) => {
  return (
    <S.ProgressStep z={3}>
      <S.Checkmark name="checkmark" completed={completed ? 1 : 0} />
      <S.ProgressStepText completed={completed ? 1 : 0}>
        <S.ProgressStepTitle>{completed ? "Completed" : "Step 2"}</S.ProgressStepTitle>
        <S.ProgressStepDescription>Select your organization type</S.ProgressStepDescription>
      </S.ProgressStepText>
    </S.ProgressStep>
  );
};

const Step3Progress = ({ completed }) => {
  return (
    <S.ProgressStep z={2}>
      <S.Checkmark name="checkmark" completed={completed ? 1 : 0} />
      <S.ProgressStepText completed={completed ? 1 : 0}>
        <S.ProgressStepTitle>{completed ? "Completed" : "Step 3"}</S.ProgressStepTitle>
        <S.ProgressStepDescription>Enter your work background</S.ProgressStepDescription>
      </S.ProgressStepText>
    </S.ProgressStep>
  );
};

function ProgressBar({ completionState }) {
  const { step1, step2, step3 } = completionState;

  const percentComplete = ((step1 + step2 + step3) / 3) * 100;

  return (
    <S.ProgressBarContainer>
      <Step1Progress completed={step1} />
      <Step2Progress completed={step2} />
      <Step3Progress completed={step3} />
      <S.ProgressBackground>
        <S.Progress percentComplete={percentComplete} />
      </S.ProgressBackground>
    </S.ProgressBarContainer>
  );
}

export default ProgressBar;
