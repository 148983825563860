import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { COLORS, WINDOW_DIMENSIONS, FONTS } from "@constants";

const COMPACT_PROGRESS_BAR_MEDIA_QUERY = `@media screen and (max-width: 920px)`;

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  padding: 0 50px;
  margin-bottom: 70px;

  ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
    max-width: unset;
  }
  ${COMPACT_PROGRESS_BAR_MEDIA_QUERY} {
    margin-bottom: 40px;
  }
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`;
export const ProgressStep = styled.div`
  display: initial;
  position: relative;
  width: 25%;
  z-index: ${(props) => props.z};
  ${COMPACT_PROGRESS_BAR_MEDIA_QUERY} {
    display: none;
  }
`;
export const Checkmark = styled(Icon)`
  color: ${COLORS.GREEN};
  display: ${(props) => (props.completed ? "initial" : "none")} !important;
  position: absolute;
  top: -5px;
  ${COMPACT_PROGRESS_BAR_MEDIA_QUERY} {
    display: none !important;
  }
`;
export const ProgressStepText = styled.div`
  color: ${(props) => (props.completed ? COLORS.NEUTRAL_500 : COLORS.BLACK)};
  ${FONTS.REGULAR_1};
  padding-left: 25px;
  padding-bottom: 16px;
`;
export const ProgressStepTitle = styled.div`
  ${FONTS.SEMIBOLD_1};
  display: block;
  ${COMPACT_PROGRESS_BAR_MEDIA_QUERY} {
    display: none !important;
  }
`;
export const ProgressStepDescription = styled.div`
  ${FONTS.REGULAR_2};

  display: block;
  ${COMPACT_PROGRESS_BAR_MEDIA_QUERY} {
    display: none !important;
  }
`;
export const ProgressBackground = styled.div`
  background-color: ${COLORS.NEUTRAL_100};
  border-radius: 10px;
  width: 100%;
  height: 6px;
  ${COMPACT_PROGRESS_BAR_MEDIA_QUERY} {
    width: 100%;
  }
`;
export const Progress = styled.div`
  height: 6px;
  width: ${(props) => `${props.percentComplete}%`};
  transition: width 0.2s ease-out;
  background-color: ${COLORS.HALO_BLUE};
  border-radius: 10px;
`;
