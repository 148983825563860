import appsignal from "@utils/appsignal";
import request from "./request";

export const getSSOEmailDomains = async (): Promise<{
  email_domains: string[];
  excluded_emails: string[];
}> => {
  try {
    const response = await request.get("/sso/email_domains");
    return response.data;
  } catch (err) {
    appsignal.sendError(err, (span) => {
      span.setAction("sso#getSSOEmailDomains");
    });
    return {
      email_domains: [],
      excluded_emails: [],
    };
  }
};
