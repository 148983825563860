import styled from "styled-components";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import { COLORS, FONTS, JS_FONTS } from "@constants";
import DROPDOWN_STYLES from "@components/library/Dropdowns/dropdownStyles";

const SelectType = (props) => {
  return props.creatable ? (
    <CreatableSelect id="clearable-select" {...props} />
  ) : props.async ? (
    <AsyncSelect id="async-select" {...props} />
  ) : (
    <Select id="regular-select" {...props} />
  );
};

export const DropdownContainer = styled.div`
  width: ${({ width }) => width || "100%"};
  ${({ containerStyles }) => `${containerStyles}`};
  input {
    box-shadow: none;
  }
  ${FONTS.REGULAR_2};
`;

export const DropdownStyles = styled(SelectType)`
  ${DROPDOWN_STYLES};

  .Select__menu {
    margin-top: 6px;
  }
  .Select__menu-list {
    max-height: 250px;
  }
`;

export const TagContainer = styled.div`
  width: fit-content;
  background-color: ${COLORS.NEUTRAL_200};
  border-radius: 6px;
  margin: 2px;
  box-sizing: border-box;
  ${FONTS.REGULAR_3};
  padding: 3px 6px 3px 6px;
  white-space: nowrap;
  display: flex;
  min-width: 0;
`;

export const CreatedTagContainer = styled(TagContainer)`
  display: inline-block;
  padding: none;
`;

export const CreateLabel = styled.div`
  ${FONTS.REGULAR_3};
  padding-left: 3px;
`;

export const menuHeaderStyle = styled.div`
  padding: 8px 12px;
  color: grey;
`;

export const customStyles = {
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused || isSelected ? COLORS.NEUTRAL_100 : "transparent",
    border: isSelected ? `1px solid ${COLORS.HALO_BLUE}` : "unset",
    borderRadius: isSelected ? `2px` : "unset",
    ":hover": {
      backgroundColor: COLORS.NEUTRAL_100,
    },
    ...JS_FONTS.REGULAR_2,
    color: COLORS.BLACK,
    cursor: "pointer",
  }),
  control: (base) => ({
    ...base,
    ...JS_FONTS.REGULAR_2,
    borderRadius: "6px",
  }),
  menu: (base) => ({
    ...base,
    ...JS_FONTS.REGULAR_2,
    zIndex: 9999,
    marginTop: "2px",
    borderRadius: "6px",
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: COLORS.NEUTRAL_100,
    ...JS_FONTS.REGULAR_2,
  }),
  singleValue: (base) => ({
    ...base,
    ...JS_FONTS.REGULAR_2,
  }),
  placeholder: (base, { isFocused }) => ({
    ...base,
    display: isFocused ? "none" : "block",
    color: COLORS.NEUTRAL_500,
    ...JS_FONTS.REGULAR_2,
  }),
};
