import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { register } from "@requests/users";
import haloLogo from "@images/logos/halo_logo.svg";
import { Button } from "@components/library";
import { getPrevUrlCookie, setJustLoggedIn } from "@utils/authUtils";
import { COLORS, WINDOW_DIMENSIONS, FONTS } from "@constants";
import { Company } from "@tsTypes/schema";

interface Props {
  company: (Pick<Company, "identifier" | "logo" | "company_name"> & Record<string, any>) | null;
  maxWidth?: string;
}

const SignUpBase = ({ company, maxWidth }: Props) => {
  if (!company) return null;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    setHasSubmitted(true);
    setError("");

    if (!email || !password || !firstName || !lastName) {
      setError("Please fill in all fields before continuing.");
      setSubmitting(false);
      return;
    }

    try {
      await register({ email, password, firstName, lastName });
      setJustLoggedIn();
      getPrevUrlCookie("/redirect_login", false);
    } catch (err) {
      setError(err.response.data.error + " Please try again.");
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth={maxWidth}>
      <Top>
        <LogoLink to={`/company/${company?.identifier}`}>
          <Logo src={company?.logo.url} />
        </LogoLink>
        <Title>
          <Header>Partner with {company?.company_name}</Header>
        </Title>
        <Link to="/login">
          <Button data-testid="auth-button" width="100%" size="md" type="submit">
            Sign in with your Halo account
          </Button>
        </Link>
        <Or>
          <Divider />
          or
          <Divider />
        </Or>
        <Form onSubmit={handleSubmit}>
          <TwoInputsContainer>
            <Input
              error={(hasSubmitted && !email) || error}
              data-testid="signup-form-first-name-input"
              name="firstName"
              value={firstName}
              type="text"
              placeholder="First name"
              onChange={(e) => {
                setHasSubmitted(false);
                setFirstName(e.target.value);
              }}
              halfWidth
            />
            <Input
              error={(hasSubmitted && !email) || error}
              data-testid="signup-form-last-name-input"
              name="lastName"
              value={lastName}
              type="text"
              placeholder="Last name"
              onChange={(e) => {
                setHasSubmitted(false);
                setLastName(e.target.value);
              }}
              halfWidth
            />
          </TwoInputsContainer>
          <Input
            error={(hasSubmitted && !email) || error}
            data-testid="signup-form-email-input"
            name="email"
            value={email}
            type="email"
            placeholder="Company or university email"
            onChange={(e) => {
              setHasSubmitted(false);
              setEmail(e.target.value);
            }}
          />
          <Input
            error={(hasSubmitted && !password) || error}
            data-testid="login-form-password-input"
            name="password"
            value={password}
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setHasSubmitted(false);
              setPassword(e.target.value);
            }}
          />
          <Button
            data-testid="auth-button"
            width="100%"
            size="md"
            disabled={submitting}
            type="submit"
            variant="secondary"
          >
            Create Account
          </Button>
          <Disclaimer>
            By signing up you agree to the&nbsp;
            <DisclaimerLink to="/terms" target="_blank" rel="noopener noreferrer">
              terms of service
            </DisclaimerLink>
            <span>{" & "}</span>
            <DisclaimerLink to="/privacy" target="_blank" rel="noopener noreferrer">
              privacy policy
            </DisclaimerLink>
          </Disclaimer>
          <Disclaimer>
            <b>Not a scientist?</b>&nbsp;
            <DisclaimerLink to="/get-started">Sign up here</DisclaimerLink>
          </Disclaimer>
        </Form>
      </Top>
      <Bottom>
        Powered by
        <LogoLink to="/">
          <Logo src={haloLogo} width="65px" />
        </LogoLink>
      </Bottom>
    </Container>
  );
};

export default SignUpBase;

const { TABLET_MEDIA_QUERY, MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const Container = styled.div`
  background: ${COLORS.WHITE};
  max-height: 100vh;
  border-radius: 12px;
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth};
    box-shadow: 0px 15.1852px 48.5926px -6.07407px rgba(0, 78, 125, 0.25);
  `}
`;

const LogoLink = styled(Link)`
  text-align: center;
`;

const Logo = styled.img`
  width: ${({ width }) => width || "168px"};
  ${({ width }) => !width && `height: 100px;`};
  object-fit: contain;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  ${FONTS.HEADING_3_SEMIBOLD}
  ${MOBILE_MEDIA_QUERY} {
    ${FONTS.HEADING_4_SEMIBOLD}
  }
`;

const Or = styled.div`
  ${FONTS.REGULAR_1}
  color: ${COLORS.NEUTRAL_500};
  display: inline-flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0px;
`;

const Divider = styled.div`
  height: 0px;
  border: 0.5px solid ${COLORS.NEUTRAL_300};
  flex-grow: 1;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const TwoInputsContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

const Input = styled.input`
  ${FONTS.REGULAR_2}

  height: 42px;
  width: ${({ halfWidth }) => (halfWidth ? "50%" : "100%")};
  padding: 10px;

  border: solid 1px ${COLORS.NEUTRAL_200};
  border-radius: 6px;

  ${({ error }) => error && `border: solid 1px ${COLORS.RED};`}

  ${MOBILE_MEDIA_QUERY} {
    border-color: ${COLORS.NEUTRAL_200};
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px 72px;
  ${TABLET_MEDIA_QUERY} {
    padding: 40px 24px;
  }
`;

const Bottom = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid ${COLORS.NEUTRAL_300};

  padding: 16px;
  gap: 16px;

  ${FONTS.REGULAR_2}

  ${MOBILE_MEDIA_QUERY} {
    margin-top: 15px;
    width: 100%;
    color: ${COLORS.NEUTRAL_500};
    ${FONTS.SEMIBOLD_1}
    text-align: left;
  }
`;

const Disclaimer = styled.div`
  ${FONTS.REGULAR_2}
  color: ${COLORS.NEUTRAL_500};
`;

const DisclaimerLink = styled(Link)`
  color: ${COLORS.NEUTRAL_500};
  text-decoration: underline;
`;
