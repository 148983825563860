// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { withRouter } from "react-router";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Container, Loader } from "semantic-ui-react";

import App from "../src/App";
import { persistor, store } from "../src/redux/store";

const LoaderContainer = () => (
  <Container textAlign="center">
    <Loader />
  </Container>
);

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<LoaderContainer />} persistor={persistor}>
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/:filter?" component={withRouter(App)} />
      </Router>
    </PersistGate>
  </Provider>
);

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Root />, document.getElementById("full-container"));
});
