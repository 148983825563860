import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "aphrodite";
import { Modal } from "semantic-ui-react";
import { toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { getRfpForRfpPage } from "@requests/rfps";

import styles from "./LoginModal.styles";
import Signup from "./Signup/Signup";
import { Login } from "./Login";
import { RfpPageRequest } from "@tsTypes/index";

export type ModalType = "signup" | "login";

function LoginModal() {
  const [modalType, setModalType] = useState<ModalType>();
  const [currentRFP, setCurrentRFP] = useState<RfpPageRequest>();

  const loginModalOpen = useSelector((state: RootState) => state.profiles.loginModalOpen);
  const previousAction = useSelector((state: RootState) => state.profiles.previousAction);
  const loginModalLanding = useSelector((state: RootState) => state.profiles.loginModalLanding);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const isRfpPage =
        window.location.pathname.includes("/research/") &&
        window.location.pathname.split("/").length > 3;

      if (isRfpPage) {
        const identifier = window.location.pathname.split("/").at(-1);
        const { request } = await getRfpForRfpPage(identifier!);
        setCurrentRFP(request);
      }
    })();
  }, []);

  useEffect(() => {
    setModalType(loginModalLanding as ModalType);
  }, [loginModalLanding]);

  const modalWidth = () => {
    if (modalType === "signup") {
      return "720px";
    }
    return "450px";
  };

  const closeLoginModal = () => {
    dispatch(toggleLoginModal({ open: false }));
  };

  return (
    <Modal
      open={loginModalOpen}
      onClose={closeLoginModal}
      size="small"
      className={css(styles.LoginModal_modal)}
      style={{ width: modalWidth(), maxWidth: "95%" }}
    >
      {modalType === "signup" && (
        <Signup
          onClose={closeLoginModal}
          currentRFP={currentRFP}
          previousAction={previousAction}
          setModalType={setModalType}
        />
      )}
      {modalType === "login" && <Login setModalType={setModalType} compat />}
    </Modal>
  );
}

export default LoginModal;
