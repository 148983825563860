import { lazyWithRetry } from "@utils/appUtils";
import { Route } from "react-router-dom";
import { seo_request_for_proposal_path } from "./routes.js";
import { formatPathForRouter } from "./utils.js";

const RfpPage = lazyWithRetry(() => import("../views/RFPPage"));

const routes = [
  <Route
    exact
    key="rfp_routes/1"
    path={formatPathForRouter(seo_request_for_proposal_path)}
    component={RfpPage}
  />,
  <Route key="rfp_routes/2" exact path="/preview-rfp/:previewHash">
    <RfpPage asPreview />
  </Route>,
];

export default routes;
