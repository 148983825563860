import { UserRole } from "@tsTypes/users";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useHistory } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { track } from "@utils/appUtils";
import { Icon } from "@components/library";
import { Popup, Button } from "semantic-ui-react";
import { COLORS, JS_FONTS, WINDOW_DIMENSIONS } from "@constants";
import { AdminTag, PremiumTag, SuperAdminTag } from "@components/library/Tags/users";
import { signOut } from "@requests/users";
import { useState } from "react";
import styled from "styled-components";
import { setShowErrorBoundary } from "@redux/actions/defaultActions";

const NavbarDropdownMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const showErrorBoundary = useSelector(
    (state: RootState) => state.defaultReducer.showErrorBoundary
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const navigate = (to: string) => {
    if (!showErrorBoundary) {
      history.push(to);
    } else {
      dispatch(setShowErrorBoundary(false));
      window.location.href = to;
    }
  };

  const navigateToLogout = async () => {
    await signOut();
    // This needs to use window.location.href instead of history.push to prevent
    // accessing the previous session via the browser cache
    window.location.href = "/login";
  };

  const navigateToProfile = () => {
    navigate(`/profile/${currentUser.first_name}-${currentUser.last_name}-${currentUser.id}`);
    setIsMenuOpen(false);
  };

  const navigateToAccount = () => {
    navigate("/account");
    setIsMenuOpen(false);
  };

  const navigateToDashboard = () => {
    navigate("/reviewer_dashboard");
    setIsMenuOpen(false);
  };

  const navigateToPublicProfile = async () => {
    await track("Clicked view public profile");
    navigate(
      `/profile/${currentUser.first_name}-${currentUser.last_name}-${currentUser.id}?public=true`
    );
    setIsMenuOpen(false);
  };

  const navigateToCompanyProfile = () => {
    navigate(`/company/${currentUser.profile_info.company_identifier}`);
    setIsMenuOpen(false);
  };

  const navigateToInbox = () => {
    navigate("/inbox");
    setIsMenuOpen(false);
  };

  const navigateToUniversityProposalLibrary = () => {
    navigate("/university_dashboard/proposals");
    setIsMenuOpen(false);
  };

  const currentPFPic = currentUser?.image?.medium?.url;

  return (
    <Popup
      trigger={
        <button
          type="button"
          data-testid="navbar-menu-trigger"
          className={css(styles.Navbar_menuWrap)}
        >
          {!currentPFPic ? (
            <div
              className={css(styles.Navbar_menuProfilePic)}
              data-testid="navbar-menu-profile-pic"
            >
              {currentUser.first_name?.[0]?.toUpperCase()}
              {currentUser.last_name?.[0]?.toUpperCase()}
            </div>
          ) : (
            <div
              className={css(styles.Navbar_menuProfilePic)}
              data-testid="navbar-menu-profile-pic"
              style={{
                backgroundImage: `url(${currentUser.image.small.url})`,
              }}
            />
          )}
          <div className={css(styles.Navbar_icon)}>
            <Icon name="Chevron Down" />
          </div>
          <div className={css(styles.Navbar_iconMobile)}>
            <Icon name="Burger" />
          </div>
        </button>
      }
      on="click"
      basic
      className={`${css(styles.Navbar_accountPopup)} transition-fadein`}
      hideOnScroll
      hoverable
      position="bottom right"
      open={isMenuOpen}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      content={
        <div
          className={css(styles.Navbar_accountPopupContainer)}
          onMouseEnter={() => setIsMenuOpen(true)}
          onMouseLeave={() => setIsMenuOpen(false)}
        >
          <div className={css(styles.Navbar_popupContainer)}>
            <div className={css(styles.Navbar_profilePicContainer)}>
              {!currentPFPic ? (
                <div className={css(styles.Navbar_regularProfilePic)}>
                  {currentUser?.first_name?.[0]?.toUpperCase()}
                  {currentUser?.last_name?.[0]?.toUpperCase()}
                </div>
              ) : (
                <div
                  className={css(styles.Navbar_popupProfilePic)}
                  style={{
                    backgroundImage: `url(${currentUser.image.medium.url})`,
                  }}
                />
              )}
            </div>
            <div className={css(styles.Navbar_popupRightPanel)}>
              <div className={css(styles.Navbar_popupName)}>
                {currentUser.first_name} {currentUser.last_name}
                {currentUser.role === UserRole.SPONSOR &&
                  currentUser.profile_info?.is_super_admin && (
                    <SuperAdminTagContainer>
                      <SuperAdminTag />
                    </SuperAdminTagContainer>
                  )}
              </div>
              <div className={css(styles.Navbar_popupPosition)}>
                {currentUser.role === UserRole.SPONSOR
                  ? currentUser.profile_info.company_name
                  : currentUser.profile_info.title}
              </div>
              {currentUser.role === UserRole.SCIENTIST && (
                <div
                  onClick={navigateToProfile}
                  className={css(styles.Navbar_profileButton)}
                  data-testid="profile-dropdown-edit-button"
                >
                  Edit Profile
                </div>
              )}
              {currentUser.role === UserRole.SPONSOR && (
                <ProfileTags>
                  {currentUser.profile_info?.premium && <PremiumTag />}
                  {currentUser.profile_info?.is_admin && <AdminTag />}
                </ProfileTags>
              )}
            </div>
          </div>
          <Button onClick={navigateToAccount} className={css(styles.Navbar_button)}>
            Account Settings
          </Button>
          {currentUser.role === UserRole.SPONSOR && !currentUser.profile_info.commenter ? (
            <Button onClick={navigateToDashboard} className={css(styles.Navbar_button)}>
              View Dashboard
            </Button>
          ) : null}
          {currentUser.id &&
            currentUser.role !== UserRole.UNIVERSITY_ADMIN &&
            !(currentUser.role === UserRole.SPONSOR && !currentUser.profile_info.verified) && (
              <Button
                onClick={navigateToInbox}
                className={css(styles.Navbar_button, styles.Navbar_inbox)}
              >
                Inbox
              </Button>
            )}
          {currentUser.role === UserRole.SCIENTIST && (
            <Button
              onClick={navigateToProfile}
              className={css(styles.Navbar_button, styles.Navbar_buttonEditProfile)}
            >
              Edit Profile
            </Button>
          )}
          {currentUser.role === UserRole.SPONSOR && (
            <Button onClick={navigateToCompanyProfile} className={css(styles.Navbar_button)}>
              {currentUser.profile_info.is_admin ? "Edit" : "View"} Company Page
            </Button>
          )}
          {currentUser.role === UserRole.UNIVERSITY_ADMIN && (
            <Button
              onClick={navigateToUniversityProposalLibrary}
              className={css(styles.Navbar_button)}
            >
              View Proposals
            </Button>
          )}
          <Button
            onClick={async () => {
              await track("Clicked on Help Center");
              window.location.href = "https://knowledge.halo.science/";
            }}
            className={css(styles.Navbar_button)}
          >
            Help Center
          </Button>
          {currentUser.role === UserRole.SCIENTIST && (
            <Button
              data-testid="view-public-profile-button"
              onClick={navigateToPublicProfile}
              className={css(styles.Navbar_button)}
            >
              View Public Profile
            </Button>
          )}
          <Button onClick={navigateToLogout} className={css(styles.Navbar_signOutButton)}>
            Sign Out
          </Button>
        </div>
      }
    />
  );
};

export default NavbarDropdownMenu;

const ProfileTags = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
`;
const SuperAdminTagContainer = styled.span`
  display: inline-block;
  position: relative;
  left: 4px;
  top: 2px;
  width: 20px;
  pointer-events: none;
`;
const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;
const styles = StyleSheet.create({
  Navbar_menuWrap: {
    display: "flex",
    alignItems: "center",
    marginLeft: 24,
    border: "none",
    background: "none",
    outline: "none",
    [TABLET_MEDIA_QUERY]: {
      marginLeft: "auto",
    },
  },
  Navbar_menuProfilePic: {
    height: "36px",
    width: "36px",
    color: COLORS.BLACK,
    backgroundColor: COLORS.NEUTRAL_100,
    marginRight: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...JS_FONTS.MEDIUM_1,
    cursor: "pointer",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    [TABLET_MEDIA_QUERY]: {
      display: "none",
    },
  },
  Navbar_icon: {
    cursor: "pointer",
    [TABLET_MEDIA_QUERY]: {
      display: "none",
    },
  },
  Navbar_iconMobile: {
    display: "none",
    [TABLET_MEDIA_QUERY]: {
      display: "block",
      fontSize: "1.2em",
    },
  },
  Navbar_accountPopup: {
    marginTop: "15px",
    marginBottom: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    border: "none",
    minWidth: "300px",
    [TABLET_MEDIA_QUERY]: {
      boxShadow: "28px 15px 24px rgba(0, 0, 0, 0.15)",
      width: "100%",
      maxWidth: "100%",
      left: 0,
    },
  },
  Navbar_accountPopupContainer: {
    width: "300px",
    padding: `10px 0px 0`,
    zIndex: 2000,
    [TABLET_MEDIA_QUERY]: {
      width: "100%",
      paddingTop: 0,
      paddingRight: "32px",
      paddingBottom: "25px",
      paddingLeft: "32px",
    },
  },
  Navbar_popupContainer: {
    display: "flex",
    marginBottom: "8px",
    [TABLET_MEDIA_QUERY]: {
      display: "none",
    },
  },
  Navbar_profilePicContainer: {
    width: "35%",
  },
  Navbar_regularProfilePic: {
    height: "80px",
    width: "80px",
    color: COLORS.BLACK,
    backgroundColor: COLORS.NEUTRAL_100,
    marginLeft: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...JS_FONTS.HEADING_2_SEMIBOLD,
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  Navbar_popupProfilePic: {
    height: "80px",
    width: "80px",
    backgroundColor: COLORS.NEUTRAL_100,
    marginLeft: "20px",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  Navbar_popupRightPanel: {
    marginLeft: "20px",
    width: "65%",
    marginTop: "8px",
    marginRight: "20px",
  },
  Navbar_popupName: {
    gap: "6px",
    color: COLORS.BLACK,
    ...JS_FONTS.MEDIUM_2,
    textTransform: "capitalize",
  },
  Navbar_popupPosition: {
    ...JS_FONTS.MEDIUM_3,
    color: COLORS.BLACK,
  },
  Navbar_profileButton: {
    cursor: "pointer",
    ...JS_FONTS.MEDIUM_2,
    color: COLORS.HALO_BLUE,
  },
  Navbar_inbox: {
    display: "none",
    [TABLET_MEDIA_QUERY]: {
      display: "unset",
    },
  },
  Navbar_buttonEditProfile: {
    display: "none",
    [TABLET_MEDIA_QUERY]: {
      display: "block",
    },
  },
  Navbar_button: {
    padding: "15px 20px",
    backgroundColor: COLORS.WHITE,
    borderBottom: `1px solid ${COLORS.NEUTRAL_200}`,
    ...JS_FONTS.MEDIUM_2,
    color: COLORS.NEUTRAL_500,
    width: "300px",
    borderRadius: "0px",
    marginRight: "0",
    textAlign: "left",
    [TABLET_MEDIA_QUERY]: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
      color: COLORS.BLACK,
      ...JS_FONTS.REGULAR_2,
    },
    ":hover": {
      backgroundColor: COLORS.NEUTRAL_50,
    },
  },
  Navbar_signOutButton: {
    padding: "15px 20px",
    ...JS_FONTS.MEDIUM_2,
    backgroundColor: COLORS.WHITE,
    color: COLORS.NEUTRAL_500,
    marginBottom: "0px",
    width: "300px",
    borderRadius: "0px",
    marginRight: "0",
    textAlign: "left",
    [TABLET_MEDIA_QUERY]: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
      color: COLORS.BLACK,
      ...JS_FONTS.REGULAR_2,
    },
    ":hover": {
      backgroundColor: COLORS.NEUTRAL_50,
    },
  },
});
