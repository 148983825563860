import styled from "styled-components";
import { COLORS } from "@constants";

export const LogoBanner = styled.div`
  position: relative;
  text-align: center;
  background-color: ${COLORS.WHITE};
  margin-top: 30px;
  margin-bottom: 67px;
  width: 100vw;
`;

export const OnboardingPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
`;
