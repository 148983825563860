import defaultReducer from "./defaultReducer";
import profileReducer from "./profileReducer";
import companyReducer from "./companyReducer";
import messengerReducer from "./messengerReducer";
import windowDimensionsReducer from "./windowDimensionsReducer";

export default {
  defaultReducer,
  profileReducer,
  messengerReducer,
  companyReducer,
  windowDimensionsReducer,
};
