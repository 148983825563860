import Step4Administrator from "./Administrator";
import Step4Scientist from "./Scientist";
import Step4Sponsor from "./Sponsor";

const Step5 = ({ userType }) => {
  if (userType === "company-sponsor") {
    return <Step4Sponsor />;
  } else if (userType === "research-administrator") {
    return <Step4Administrator />;
  }
  return <Step4Scientist />;
};

export default Step5;
