type UserRole = 0 | 1 | 2 | "";

// Allow saving type-specific prev urls
// Usage: Set different prev urls for different user types
export const setPrevUrlCookie = (url = window.location.href, role: UserRole = "") => {
  if (window.location.pathname !== "/") {
    docCookies.setItem(`prevUrl${role}`, url, 1800, "/");
  }
};

export const getPrevUrlCookie = (targetLink, deletePrev = true, role: UserRole = "") => {
  // Try type-specific url before general url
  const prevUrl = docCookies.getItem(`prevUrl${role}`) || docCookies.getItem(`prevUrl`);
  if (prevUrl) {
    if (deletePrev) {
      // Make sure to clean up all general and type-specific urls
      docCookies.removeItem("prevUrl", "/");
      docCookies.removeItem("prevUrl0", "/");
      docCookies.removeItem("prevUrl1", "/");
      docCookies.removeItem("prevUrl2", "/");
    }

    if (window.location.href === prevUrl) {
      location.reload();
    } else {
      window.location.href = prevUrl;
    }
  } else {
    window.location.href = targetLink;
  }
};

export const setWlCompanyCookie = (company_id) => {
  docCookies.setItem("wlCompanyId", company_id, 1800);
};

export const getWlCompanyCookie = () => {
  return docCookies.getItem("wlCompanyId");
};
export const setJustLoggedIn = () => {
  docCookies.setItem("justLoggedIn", true, 1800, "/");
};

export const getJustLoggedIn = () => {
  return docCookies.removeItem("justLoggedIn", "/");
};
