import { useEffect } from "react";
import { Link } from "react-router-dom";

import { convertToSnakeCase } from "@utils/textUtils";
import api from "src/requests/request";
import * as S from "../StepStyles";
import KeywordsDropdown from "@components/library/Dropdowns/KeywordsDropdown/KeywordsDropdown";
import { useCompanyOptions } from "../Hooks";
import { SponsorRole } from "@tsTypes/sponsorProfiles";
import { TextInput } from "@components/library";
import { COMPANY_SPONSOR_TITLE_LIMIT } from "./Step3";

const fetchCompanySuggestions = async (email) => {
  const responseJson = await api.get("/onboarding/company_suggestions", { params: { email } });
  return responseJson.data?.company_suggestions || [];
};

const roleDropdownOptions = Object.values(SponsorRole).map((text) => ({
  key: convertToSnakeCase(text),
  text: text,
  value: text,
}));

function CompanySponsor({ profileInfo, setProfileInfo, email, prefillCompanyName = "" }) {
  const { areasOfExpertise, techniques } = profileInfo;

  const [companyOptions] = useCompanyOptions();

  useEffect(() => {
    // Returns array of suggestions. Current UI only calls on initial load.
    // set company based on first suggestion
    (async () => {
      const fetchedSuggestions = await fetchCompanySuggestions(email);
      if (fetchedSuggestions.length) {
        const suggestedCompany = fetchedSuggestions[0];
        setProfileInfo({
          ...profileInfo,
          company: suggestedCompany,
        });
      }
    })();
  }, []);

  const handleCompany = (e, selection) => {
    if (selection?.action === "select-option" || selection?.action === "create-option") {
      setProfileInfo({
        ...profileInfo,
        company: e.value,
      });
    }
  };

  const handleRole = (e, selection) => {
    if (selection?.action == "select-option") {
      setProfileInfo({
        ...profileInfo,
        role: e.value,
      });
    }
  };

  return (
    <>
      <S.DropdownRow>
        <S.DropdownTitle>Company Name</S.DropdownTitle>
        {prefillCompanyName.length > 0 ? (
          <S.Input type="text" value={prefillCompanyName} tabIndex={-1} fixed readOnly />
        ) : (
          <S.CompanyDropdownNew
            placeholder="Select your company or create new"
            creatable
            options={companyOptions}
            onChange={handleCompany}
            value={profileInfo.company}
            data-testid="company-sponsor-step-4-company-dropdown"
            disableIconColor
          />
        )}
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>Which of the following best describes your role?</S.DropdownTitle>
        <S.CompanyDropdownNew
          placeholder="Select your role"
          options={roleDropdownOptions}
          onChange={handleRole}
          value={profileInfo.role}
          data-testid="company-sponsor-step-4-role-dropdown"
          disableIconColor
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What’s your job title?</S.DropdownTitle>
        <TextInput
          value={profileInfo.title || ""}
          placeholder="Enter your job title"
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              title: e.target.value,
            });
          }}
          errors={[
            {
              hasError: profileInfo.title?.length > COMPANY_SPONSOR_TITLE_LIMIT,
              errorMessage: `Job title must be less than ${COMPANY_SPONSOR_TITLE_LIMIT} characters`,
            },
          ]}
          hideClearButton
          data-testid="company-sponsor-step-4-title-field"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What are your areas of expertise?</S.DropdownTitle>
        <S.DropdownSubtitle>You can add up to 5.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="areas_of_expertise"
          value={areasOfExpertise ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, areasOfExpertise: selection })}
          maxValues={5}
          placeholder="Add areas of expertise"
          showSuggestions
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What are your investigational techniques?</S.DropdownTitle>
        <S.DropdownSubtitle>
          You can add up to 15.{" "}
          <Link
            to="/contact?message=Hello, I can't find all of my research techniques as an option. My techniques are:"
            target="_blank"
          >
            Contact us
          </Link>{" "}
          if you don&rsquo;t see your techniques.
        </S.DropdownSubtitle>
        <KeywordsDropdown
          type="techniques"
          value={techniques ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, techniques: selection })}
          maxValues={15}
          placeholder="Add techniques"
          showSuggestions
        />
      </S.DropdownRow>
    </>
  );
}

export default CompanySponsor;
