import { Button } from "@components/library";
import { FEATURE_FLAGS } from "@constants";
import { signOut } from "@requests/users";
import styled from "styled-components";
import RadioButton from "../../../components/libraryOld/RadioButtons/RadioButton";
import * as S from "../StepStyles";

function Step2({ values, setWork, setRole, onContinue }) {
  const { work, role } = values;
  const isWhiteLabel = location.pathname.startsWith("/partner");

  if (isWhiteLabel) {
    return (
      <S.StepContainer>
        <S.StepTitle>Where do you work?</S.StepTitle>
        <S.Padding height={25} />
        <RadioButton
          testid="ri-scientist-radio-button"
          text="Research Institution"
          description="e.g. universities, research center"
          value="research-scientist"
          selected={role}
          onChange={(v) => setRole(v)}
        />
        <RadioButton
          testid="company-scientist-radio-button"
          text="Company"
          description="e.g. startups, suppliers"
          value="company-scientist"
          selected={role}
          onChange={(v) => setRole(v)}
        />
        <ButtonContainer work={work}>
          <Button
            type="button"
            data-testid="step2-submit-button"
            disabled={role === ""}
            onClick={onContinue}
            width="120px"
          >
            Continue
          </Button>
        </ButtonContainer>
      </S.StepContainer>
    );
  }

  return (
    <S.StepContainer>
      <S.StepTitle>Where do you work?</S.StepTitle>
      <RadioButton
        testid="company-radio-button"
        text="Company"
        description="e.g. Bayer, PepsiCo, startups"
        value="company"
        selected={work}
        onChange={(v) => setWork(v)}
      />
      <RadioButton
        testid="ri-radio-button"
        text="Research Institution"
        description="e.g. universities, research center"
        value="research-institution"
        selected={work}
        onChange={(v) => setWork(v)}
      />
      <S.Padding height={25} />
      {work === "research-institution" && (
        <>
          <S.StepTitle>Are you a researcher or an administrator?</S.StepTitle>
          <RadioButton
            testid="ri-scientist-radio-button"
            text="Researcher"
            description="I conduct research."
            value="research-scientist"
            selected={role}
            onChange={(v) => setRole(v)}
          />
          <RadioButton
            testid="ri-admin-radio-button"
            text="Administrator"
            description="I connect faculty with research opportunities."
            value="research-administrator"
            selected={role}
            onChange={(v) => setRole(v)}
          />
        </>
      )}
      {work === "company" && (
        <>
          <S.StepTitle>What would you like to do on Halo?</S.StepTitle>
          <RadioButton
            testid="company-sponsor-radio-button"
            text="Find solutions"
            description="Post RFPs to our global network of scientists and startups"
            value="company-sponsor"
            selected={role}
            onChange={(v) => setRole(v)}
          />
          <RadioButton
            testid="company-scientist-radio-button"
            text="Submit proposals"
            description="Respond to RFPs to receive funding, expertise and other benefits"
            value="company-scientist"
            selected={role}
            onChange={(v) => setRole(v)}
          />
        </>
      )}
      <ButtonContainer work={work}>
        {FEATURE_FLAGS.SIGN_OUT_ON_ONBOARDING && (
          <Button
            type="button"
            onClick={async () => {
              await signOut();
            }}
          >
            Sign Out
          </Button>
        )}
        <Button
          type="button"
          data-testid="step2-submit-button"
          disabled={work === "" || role === ""}
          onClick={onContinue}
          width="120px"
        >
          Continue
        </Button>
      </ButtonContainer>
    </S.StepContainer>
  );
}

export default Step2;

const ButtonContainer = styled.div`
  margin-top: ${({ work }) => (work ? "40px" : "0px")};
`;
