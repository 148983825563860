import styled from "styled-components";
import { BlankModal } from "@components/library";
import Participants from "./Conversation/Participants";

const ParticipantsInfoModal = ({ messengerThread, isOpen, closeModal }) => {
  return (
    <BlankModal
      isOpen={isOpen}
      onClose={closeModal}
      headerText="Who can see this conversation"
      width="540px"
      height="534px"
      disableBackgroundScroll={false}
    >
      <Container>
        <Participants currentThread={messengerThread} isModal={true} />
      </Container>
    </BlankModal>
  );
};

export default ParticipantsInfoModal;

const Container = styled.div`
  padding: 20px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
`;
