import NavbarLink from "@components/Navbar/NavbarLink";
import { t } from "@utils/i18n";
import styled from "styled-components";
import { UserLinks } from "./ScientistNavbarLinks";

const LoggedOutNavbarLinks = () => {
  return (
    <Container>
      <NavbarLink to="/home" asAnchor>
        {t("components.navbar.links.marketplace")}
      </NavbarLink>
      <NavbarLink to="https://blog.halo.science/" asAnchor>
        {t("components.navbar.links.blog")}
      </NavbarLink>
      <NavbarLink to="/scientists" asAnchor>
        {t("components.navbar.links.scientists")}
      </NavbarLink>
    </Container>
  );
};

export default LoggedOutNavbarLinks;

const Container = styled(UserLinks)`
  margin-left: 36px;
`;
