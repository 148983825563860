import { Link } from "react-router-dom";
import api from "src/requests/request";
import * as S from "../StepStyles";
import KeywordsDropdown from "@components/library/Dropdowns/KeywordsDropdown/KeywordsDropdown";
import { COUNTRY_OPTIONS } from "@constants";
import { UniversityDropdown } from "@components/library";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";

const userRoleOptions = [
  "Principal Investigator",
  "Postdoctoral Scientist",
  "Research Associate",
  "PhD Candidate",
].map((text) => ({
  key: text,
  text: text,
  value: text,
}));

function ResearchInstitutionScientist({
  profileInfo,
  setProfileInfo,
  prefillInstitutionName = "",
}) {
  const {
    university,
    universityHasCountry,
    role,
    disciplines,
    areasOfExpertise,
    techniques,
    country_code,
  } = profileInfo;

  const handleCountry = (e) => {
    setProfileInfo({
      ...profileInfo,
      country_code: e.value,
    });
  };

  const handleRole = (e, selection) => {
    if (selection?.action === "select-option") {
      setProfileInfo({
        ...profileInfo,
        role: e.value,
      });
    }
  };

  const handleSelectUniversityOption = async (option) => {
    if (!option) return;

    const responseJson = await api.get("/universities/has_country", {
      params: { name: getValueFromOption(option) },
    });
    const { has_country } = responseJson.data;

    setProfileInfo({
      ...profileInfo,
      university: getValueFromOption(option),
      universityHasCountry: has_country,
    });
  };

  return (
    <>
      <S.DropdownRow>
        <S.DropdownTitle>Research Institution</S.DropdownTitle>
        {prefillInstitutionName.length > 0 ? (
          <S.Input type="text" value={prefillInstitutionName} tabIndex={-1} fixed readOnly />
        ) : (
          <>
            <UniversityDropdown
              value={getOptionFromValue(university)}
              onChange={handleSelectUniversityOption}
              data-testid="ri-scientist-institution-name-input"
            />
            <S.ContactLink>
              <a
                style={{ textDecoration: "underline" }}
                href="/contact?message=Hello, I can't find my institution as an option. My institution is: "
                target="_blank"
              >
                Contact us
              </a>{" "}
              if you don&rsquo;t see your institution
            </S.ContactLink>
          </>
        )}
      </S.DropdownRow>
      {!universityHasCountry && prefillInstitutionName.length === 0 && (
        <S.DropdownRow>
          <S.DropdownTitle>Where is your institution located?</S.DropdownTitle>
          <S.CompanyDropdownNew
            selection
            placeholder="Search countries"
            value={country_code}
            options={COUNTRY_OPTIONS}
            onChange={handleCountry}
            closeMenuOnSelect
            disableIconColor
            defaultShadow
            data-testid="ri-scientist-institution-location-input"
          />
        </S.DropdownRow>
      )}
      <S.DropdownRow>
        <S.DropdownTitle>Which of the following best describes your role?</S.DropdownTitle>
        <S.CompanyDropdownNew
          placeholder="Select your role"
          options={userRoleOptions}
          onChange={handleRole}
          value={role}
          data-testid="ri-scientist-role-input"
          disableIconColor
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>
          Which academic departments or disciplines are most relevant to your work?
        </S.DropdownTitle>
        <S.DropdownSubtitle>You can add up to 3.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="disciplines"
          value={disciplines ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, disciplines: selection })}
          maxValues={3}
          placeholder="Add department or discipline"
          showSuggestions
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What are your areas of expertise?</S.DropdownTitle>
        <S.DropdownSubtitle>You can add up to 5.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="areas_of_expertise"
          value={areasOfExpertise ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, areasOfExpertise: selection })}
          maxValues={5}
          placeholder="Add areas of expertise"
          showSuggestions
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What are your investigational techniques?</S.DropdownTitle>
        <S.DropdownSubtitle>
          You can add up to 15.{" "}
          <Link
            to="/contact?message=Hello, I can't find all of my research techniques as an option. My techniques are:"
            target="_blank"
          >
            Contact us
          </Link>{" "}
          if you don&rsquo;t see your techniques.
        </S.DropdownSubtitle>
        <KeywordsDropdown
          type="techniques"
          value={techniques ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, techniques: selection })}
          maxValues={15}
          placeholder="Add techniques"
          showSuggestions
        />
      </S.DropdownRow>
    </>
  );
}

export default ResearchInstitutionScientist;
