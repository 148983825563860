import {
  ScientistProfilePartial,
  ShowUser,
  SponsorProfilePartial,
  UsersShowResponse,
} from "@tsTypes/index";
import { UniversityProfile } from "@tsTypes/schema";
import { UserRole } from "@tsTypes/users";
import axios, { AxiosError } from "axios";
import { USER_ROLES } from "src/constants/users";
import request from "./request";

export type ScientistUser = ShowUser & {
  role: UserRole.SCIENTIST;
  profile_info: ScientistProfilePartial;
};

export type SponsorUser = ShowUser & {
  role: UserRole.SPONSOR;
  profile_info: SponsorProfilePartial;
};

export type UniversityAdminUser = ShowUser & {
  role: UserRole.UNIVERSITY_ADMIN;
  profile_info: UniversityProfile;
};

export class UserNotVerifiedError extends Error {}

export type AnyTypeUser = ScientistUser | SponsorUser | UniversityAdminUser;

export const getUser = async (
  userId: number,
  fetchFromSession = false
): Promise<UsersShowResponse | null> => {
  try {
    const response = fetchFromSession
      ? await request.get("/me", {
          // The timestamp param is added to force a new request as opposed to using a cached response
          params: { timestamp: Number(new Date()) },
        })
      : await request.get(`/users/${userId}`);

    return response.data;
  } catch (error) {
    switch (error.response?.status) {
      case 401:
      case 404:
        break;
      default:
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
    }
    return null;
  }
};

export const signOut = async () => {
  await request.delete("/sign_out");
  // This needs to use window.location.href instead of history.push to prevent
  // accessing the previous session via the browser cache
  window.location.href = "/login";
};

export const signIn = async ({ email, password }) =>
  request.post("/users/sign_in", {
    user: {
      email,
      password,
    },
  });

export const register = async ({
  email,
  password,
  firstName,
  lastName,
  role,
  invitedUserId = undefined,
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role?: UserRole;
  invitedUserId?: string;
}) =>
  request.post("/users", {
    user: {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      role,
      invited_user_id: invitedUserId,
    },
  });

export const getUserFollows = async (userId) => {
  const response = await request.get(`/user_follows`, {
    params: {
      user_id: userId,
    },
  });
  return response.data;
};

export const onboard = (userType, profileInfo) => {
  let data;

  switch (userType) {
    case "scientist":
      data = {
        account_type: 0,
        how_did_you_hear_about_us: profileInfo.howDidYouHearAboutUs,
        profile_info: {
          title: profileInfo.role,
          disciplines: profileInfo.disciplines,
          areas_of_expertise: profileInfo.areasOfExpertise,
          techniques: profileInfo.techniques,
          ...(profileInfo?.university && {
            university: profileInfo?.university,
          }),
        },
        ...(!profileInfo?.university && {
          startup: {
            startup_name: profileInfo.companyName,
            url: encodeURI(profileInfo.companyURL),
            primary_affiliation: true,
          },
        }),
        ...(profileInfo?.country_code && {
          country_code: profileInfo?.country_code,
        }),
      };
      break;
    case "sponsor":
      data = {
        account_type: 1,
        how_did_you_hear_about_us: profileInfo.howDidYouHearAboutUs,
        profile_info: {
          role: profileInfo.role,
          title: profileInfo.title,
          areas_of_expertise: profileInfo.areasOfExpertise,
          techniques: profileInfo.techniques,
        },
        company: profileInfo.company,
      };
      break;
    case "admin":
      data = {
        account_type: 2,
        how_did_you_hear_about_us: profileInfo.howDidYouHearAboutUs,
        profile_info: {
          role: profileInfo.role,
          university: profileInfo.university,
          university_expertise: profileInfo.universityExpertise.join(","),
        },
        ...(profileInfo?.country_code && {
          country_code: profileInfo?.country_code,
        }),
      };
      break;
  }
  return request
    .post(`/onboarding`, data)
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error?.response?.status === 403) {
        throw new UserNotVerifiedError(error?.response?.data?.message);
      } else {
        throw error;
      }
    })
    .then((response) => response.data.user);
};

export const updateUserKeywords = (
  userId: string,
  keywords: { disciplines: string[]; areasOfExpertise: string[]; techniques: string[] }
) => {
  return request.put(`/users/${userId}`, {
    disciplines: keywords.disciplines,
    areas_of_expertise: keywords.areasOfExpertise,
    techniques: keywords.techniques,
  });
};

export const updateUserProfile = async (userId: number, role: number, profileInfo: any) => {
  let url = "";

  switch (role) {
    case USER_ROLES.scientist:
      url = `/scientist_profile/${userId}`;
      break;
    case USER_ROLES.sponsor:
      url = `/sponsor_profiles/${profileInfo.id}`;
      break;
    case USER_ROLES.university_admin:
      url = `/university_profiles/${profileInfo.id}`;
      break;
  }

  const response = await request.put(url, {
    ...profileInfo,
    user_id: userId,
  });

  return response.data.user;
};

export const skipUpdateUserKeywords = (userId: string) => {
  return request.put(`/users/${userId}`, {
    has_updated_taxonomy: true,
  });
};

export const resendEmailInvite = () => request.post("/register/resend_email_verification");

export const verifyUser = async (verificationCode: string): Promise<boolean> => {
  try {
    await request.post("/onboarding/verify", { verification_code: verificationCode });

    return true;
  } catch (error) {
    if (error.response.status === 401) return false;
    throw error;
  }
};
