import api from "src/requests/request";
import { convertToSnakeCase } from "@utils/textUtils";
import * as S from "../StepStyles";
import { UniversityAdminRoles } from "@tsTypes/universityProfiles";
import { COUNTRY_OPTIONS } from "@constants";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { UniversityDropdown } from "@components/library";

const userRoleOptions = Object.values(UniversityAdminRoles).map((text) => ({
  key: convertToSnakeCase(text),
  text: text,
  value: text,
}));

const expertiseOptions = [
  "Healthcare and Life Sciences",
  "Engineering and Physical Sciences",
  "New Venture Creation",
  "Data Science",
  "Agriculture",
].map((text) => ({
  key: convertToSnakeCase(text),
  text: text,
  value: text,
}));

function ResearchInstitutionAdmin({ profileInfo, setProfileInfo, prefillInstitutionName = "" }) {
  const { university, universityHasCountry, role, universityExpertise, country_code } = profileInfo;

  const handleRole = (e, selection) => {
    if (selection?.action === "select-option") {
      setProfileInfo({ ...profileInfo, role: e.value });
    }
  };

  const handleExpertise = (e, selection) => {
    if (selection?.action === "select-option" && e.length <= 3) {
      setProfileInfo({
        ...profileInfo,
        universityExpertise: e.map((v) => v.value) || [],
      });
    }
  };

  const handleCountry = (e, selection) => {
    if (selection?.action === "select-option") {
      setProfileInfo({
        ...profileInfo,
        country_code: e.value,
      });
    }
  };

  const handleSelectUniversityOption = async (option) => {
    if (!option) return;

    const responseJson = await api.get("/universities/has_country", {
      params: { name: getValueFromOption(option) },
    });

    const { has_country } = responseJson.data;

    setProfileInfo({
      ...profileInfo,
      university: getValueFromOption(option),
      universityHasCountry: has_country,
    });
  };

  return (
    <>
      <S.DropdownRow>
        <S.DropdownTitle>Research Institution</S.DropdownTitle>
        {prefillInstitutionName.length > 0 ? (
          <S.Input type="text" value={prefillInstitutionName} tabIndex={-1} fixed readOnly />
        ) : (
          <>
            <UniversityDropdown
              value={getOptionFromValue(university)}
              onChange={handleSelectUniversityOption}
              data-testid="ri-admin-institution-name-input"
            />
            <S.ContactLink>
              <a
                style={{ textDecoration: "underline" }}
                href="/contact?message=Hello, I can't find my institution as an option. My institution is: "
                target="_blank"
              >
                Contact us
              </a>{" "}
              if you don&rsquo;t see your institution
            </S.ContactLink>
          </>
        )}
      </S.DropdownRow>
      {!universityHasCountry && prefillInstitutionName.length === 0 && (
        <S.DropdownRow>
          <S.DropdownTitle>Where is your institution located?</S.DropdownTitle>
          <S.CompanyDropdownNew
            selection
            placeholder="Search countries"
            value={country_code}
            options={COUNTRY_OPTIONS}
            onChange={handleCountry}
            closeMenuOnSelect
            disableIconColor
            defaultShadow
            data-testid="ri-admin-institution-loaction-input"
          />
        </S.DropdownRow>
      )}
      <S.DropdownRow>
        <S.DropdownTitle>Which of the following best describes your role?</S.DropdownTitle>
        <S.CompanyDropdownNew
          placeholder="Select your role"
          options={userRoleOptions}
          onChange={handleRole}
          value={role}
          data-testid="ri-admin-role-input"
          disableIconColor
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>
          Where do you focus your efforts on behalf of your faculty?
        </S.DropdownTitle>
        <S.CompanyDropdownNew
          placeholder="Select your focus"
          isMulti
          closeMenuOnSelect={false}
          value={universityExpertise || []}
          options={expertiseOptions}
          onChange={handleExpertise}
          maxOptions={3}
          data-testid="ri-admin-research-areas-input"
          isClearable={false}
          disableIconColor
        />
      </S.DropdownRow>
    </>
  );
}

export default ResearchInstitutionAdmin;
