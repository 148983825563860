import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "@redux/store";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import BlankModal, { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import { Button, MultiSelectDropdown } from "@components/library";
import { updateUserProfile } from "@requests/users";
import { setCurrentUser } from "@redux/actions/profileActions";
import toast from "@components/Toast";
import { t } from "@utils/i18n";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { UniversityExpertise } from "@tsTypes/universityProfiles";
import appsignal from "@utils/appsignal";

const expertiseOptions = Object.values(UniversityExpertise).map((e) => getOptionFromValue(e)!);

const UniversityExpertiseUpdateModal = ({ isOpen, onClose }: BlankModalProps) => {
  if (!isOpen) return null;

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const dispatch = useDispatch();

  const [expertise, setExpertise] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const concatExpertise = expertise.join(",");
    try {
      await updateUserProfile(currentUser.id, currentUser.role, {
        university_expertise: concatExpertise,
      });
      dispatch(
        setCurrentUser({
          ...currentUser,
          profile_info: {
            ...currentUser.profile_info,
            university_expertise: concatExpertise,
          },
        })
      );
      onClose();
    } catch (err) {
      toast.error(t("error.default"));
      appsignal.sendError(err, (span) => {
        span.setAction("UniversityExpertiseUpdateModal#handleSubmit");
        span.setTags({
          expertise: concatExpertise,
        });
      });
    }
    setIsSubmitting(false);
  };

  return (
    <BlankModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      closeOnDimmerClick={false}
      disableBackgroundScroll={true}
      headerText={t("components.modals.university_expertise_update_modal.header", {
        first_name: currentUser.first_name,
      })}
    >
      <Form onSubmit={handleSubmit}>
        <Content>
          <Title>{t("components.modals.university_expertise_update_modal.title")}</Title>
          <Text>{t("components.modals.university_expertise_update_modal.text")}</Text>
          <Label>Select up to 3</Label>
          <MultiSelectDropdown
            value={expertise.map((e) => getOptionFromValue(e)!)}
            onChange={(e) => setExpertise(e.map(getValueFromOption))}
            options={expertiseOptions}
            maxValues={3}
            placeholder="Search areas of expertise"
            isPortal
          />
        </Content>
        <Footer>
          <Button size="md" disabled={isSubmitting || !expertise.length}>
            Update
          </Button>
        </Footer>
      </Form>
    </BlankModal>
  );
};

export default UniversityExpertiseUpdateModal;

export const Form = styled.form`
  width: 600px;

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    width: 450px;
  }
`;

const Content = styled.div`
  padding: 20px;
`;

const Footer = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${COLORS.NEUTRAL_300};
`;

const Title = styled.div`
  ${FONTS.SEMIBOLD_1}
`;

const Text = styled.div`
  ${FONTS.REGULAR_1}
  margin-bottom: 40px;
`;

const Label = styled.div`
  ${FONTS.REGULAR_3}
  margin-bottom: 4px;
`;
