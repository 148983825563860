import styled from "styled-components";
import { COLORS, FONTS } from "@constants";
import { Icon } from "@components/library";

const SSOEnabledHint = ({ text = "Single sign-on enabled" }) => (
  <Hint>
    <Icon name="Lock" color={COLORS.NEUTRAL_500} size="xs" />
    {text}
  </Hint>
);

export { SSOEnabledHint };

const Hint = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${COLORS.NEUTRAL_500};
  ${FONTS.REGULAR_2}
`;
