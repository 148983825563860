export enum UniversityAdminRoles {
  CORPORATE_RELATIONS = "Corporate Relations",
  TECH_TRANSFER = "Tech Transfer",
  RESEARCH_DEVELOPMENT = "Research Development",
}

export enum UniversityExpertise {
  HEALTHCARE_AND_LIFE_SCIENCES = "Healthcare and Life Sciences",
  ENGINEERING_AND_PHYSICAL_SCIENCES = "Engineering and Physical Sciences",
  NEW_VENTURE_CREATION = "New Venture Creation",
  DATA_SCIENCE = "Data Science",
  AGRICULTURE = "Agriculture",
}
