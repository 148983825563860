import _ from "lodash";
import {
  SET_CURRENT_USER,
  LOAD_CURRENT_USER,
  SET_PATENTS,
  LOAD_PATENTS,
  SET_PUBLICATIONS,
  LOAD_PUBLICATIONS,
  SET_FUNDINGS,
  LOAD_FUNDINGS,
  TOGGLE_FUNDING_MODAL,
  TOGGLE_PUBLICATION_MODAL,
  TOGGLE_PATENT_MODAL,
  TOGGLE_LOGIN_MODAL,
  START_DELETE_PROPOSAL,
  FINISH_DELETE_PROPOSAL,
  LOADING_RESEARCH_INTEREST_FOLLOWS,
  UPDATE_RESEARCH_INTEREST_FOLLOWS,
  SET_UNREAD_COUNT,
  SET_PROPOSAL_EVAL,
  UPDATE_PROFILE_IMAGE,
  FINISH_FOLLOW_UNFOLLOW_COMPANY,
} from "../actions/profileActions";

const initialState = {
  currentUser: {
    profile_info: {},
  },
  loadingCurrentUser: false,
  loadingPatents: false,
  loadingPublications: false,
  loadingFundings: false,
  fundingModalOpen: false,
  publicationModalOpen: false,
  patentModalOpen: false,
  loginModalOpen: false,
  loginModalLanding: "signup", // or "login"
  previousAction: "",
  currentPatentId: null,
  currentPublicationId: null,
  currentFundingId: null,
  patents: [],
  publications: [],
  fundings: [],
  loadingResearchInterestFollows: false,
  unreadCount: 0,
  proposalEval: {},
};

const profileReducer = (state = initialState, action) => {
  let newObj;
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        loadingCurrentUser: false,
      };
    case LOAD_CURRENT_USER:
      return {
        ...state,
        loadingCurrentUser: true,
      };
    case SET_PUBLICATIONS:
      return {
        ...state,
        publications: action.payload,
        loadingPublications: false,
      };
    case LOAD_PUBLICATIONS:
      return {
        ...state,
        loadingPublications: true,
      };
    case SET_PATENTS:
      return {
        ...state,
        patents: action.payload,
        loadingPatents: false,
      };
    case LOAD_PATENTS:
      return {
        ...state,
        loadingPatents: true,
      };
    case SET_FUNDINGS:
      return {
        ...state,
        fundings: action.payload,
        loadingFundings: false,
      };
    case LOAD_FUNDINGS:
      return {
        ...state,
        loadingFundings: true,
      };
    case TOGGLE_FUNDING_MODAL:
      return {
        ...state,
        fundingModalOpen: action.payload.open,
        currentFundingId: action.payload.open ? action.payload.currentFundingId : null,
      };
    case TOGGLE_PUBLICATION_MODAL:
      return {
        ...state,
        publicationModalOpen: action.payload.open,
        currentPublicationId: action.payload.open ? action.payload.currentPublicationId : null,
      };
    case TOGGLE_PATENT_MODAL:
      return {
        ...state,
        patentModalOpen: action.payload.open,
        currentPatentId: action.payload.open ? action.payload.currentPatentId : null,
      };
    case TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpen: action.payload.open,
        previousAction: action.payload.previousAction,
        prePopulatedSignupEmail: action.payload.prePopulatedSignupEmail,
        loginModalLanding: action.payload.landing ?? "signup",
      };
    case START_DELETE_PROPOSAL:
      return {
        ...state,
        deletingProposal: true,
      };
    case FINISH_DELETE_PROPOSAL:
      newObj = _.merge({}, state);
      if (action.payload && action.payload.proposalId) {
        newObj.currentUser.proposals = _.filter(
          newObj.currentUser.proposals,
          (p) => p.id !== action.payload.proposalId
        );
      }
      return {
        ...newObj,
        deletingProposal: false,
      };
    case FINISH_FOLLOW_UNFOLLOW_COMPANY:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profile_info: {
            ...state.currentUser.profile_info,
            followed_companies_info: action.payload.isFollowing
              ? [...state.currentUser.profile_info.followed_companies_info, action.payload.company]
              : state.currentUser.profile_info.followed_companies_info.filter(
                  (company) => company.id !== action.payload.company.id
                ),
          },
        },
      };
    case LOADING_RESEARCH_INTEREST_FOLLOWS:
      return {
        ...state,
        loadingResearchInterestFollows: action.payload,
      };
    case UPDATE_RESEARCH_INTEREST_FOLLOWS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profile_info: {
            ...state.currentUser.profile_info,
            followed_research_interests: action.payload,
          },
        },
        loadingResearchInterestFollows: false,
      };
    case SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };
    case SET_PROPOSAL_EVAL:
      return {
        ...state,
        proposalEval: action.payload,
      };
    case UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          image: action.payload,
        },
      };
    default:
      return state;
  }
};
export default profileReducer;
