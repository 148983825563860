import { Button, InfoBanner } from "@components/library";
import BlankModal, { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import { FONTS } from "@constants";

import styled from "styled-components";

interface Props {
  openFileBrowser: () => any;
}

const AttachmentsInfoModal = ({
  openFileBrowser,
  isOpen,
  onClose,
}: Props & Pick<BlankModalProps, "isOpen" | "onClose">) => {
  if (!isOpen) return null;

  return (
    <BlankModal isOpen={isOpen} onClose={onClose} width="494px" disableBackgroundScroll>
      <Content>
        <Heading>Attachment Requirements</Heading>
        <List>
          <li>The maximum size for each is 5MB</li>
          <li>The file format needs to be PNG, JPG, or PDF</li>
        </List>
        <InfoBanner
          type="attention-orange"
          text="Do not include confidential information"
          shouldDisplay
          width="100%"
        />
        <ButtonContainer>
          <Button width="132px" onClick={openFileBrowser}>
            Got it
          </Button>
        </ButtonContainer>
      </Content>
    </BlankModal>
  );
};

export default AttachmentsInfoModal;

const Content = styled.div`
  padding: 48px 56px 24px;
`;

const Heading = styled.div`
  ${FONTS.HEADING_3_SEMIBOLD}
`;

const List = styled.ul`
  ${FONTS.REGULAR_1}
  margin: 16px 0;
  list-style: inside;
  padding-left: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 56px;
`;
