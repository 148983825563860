import { useHistory } from "react-router-dom";
import { getPrevUrlCookie } from "@utils/authUtils";
import { Button } from "@components/library";
import * as S from "../StepStyles";

const Step4Sponsor = () => {
  const history = useHistory();

  const routeToRFPCreation = () => {
    history.push("/reviewer_dashboard?create=true");
  };

  const routeToDashboard = () => {
    getPrevUrlCookie("/reviewer_dashboard", true, 1);
  };

  return (
    <S.StepContainer>
      <S.StepTitle>Registration complete!</S.StepTitle>
      <S.DropdownRow>
        <div>
          You can now post your first opportunity and start receiving proposals from thousands of
          researchers across 70+ countries.
        </div>
      </S.DropdownRow>
      <S.DropdownRow>
        <div>Before we take you to your dashboard, would you like to create an opportunity?</div>
      </S.DropdownRow>
      <S.ButtonContainer>
        <Button
          type="button"
          variant="ghost"
          data-testid="completed-sponsor-onboarding-button"
          disabled={false}
          onClick={() => routeToDashboard()}
        >
          Skip for Later
        </Button>
        <Button type="button" disabled={false} onClick={routeToRFPCreation}>
          Create your first opportunity
        </Button>
      </S.ButtonContainer>
    </S.StepContainer>
  );
};

export default Step4Sponsor;
